/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import atob from 'atob';
import btoa from 'btoa';
import randomstring from 'randomstring';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ApiTempo from '../../services/ApiTempo';
import Loading from '../../components/Loading/Index';
import './Style.css';

function Automaticas({ estacao }) {
  const params = useParams();

  const options = [
    { key: 1, text: 'Temperatura', value: 1 },
    { key: 2, text: 'Temperatura Diária', value: 2 },
    { key: 3, text: 'Chuva', value: 3 },
    { key: 4, text: 'Chuva Diária', value: 4 },
    { key: 5, text: 'Chuva Mensal', value: 5 },
    { key: 6, text: 'Umidade', value: 6 },
    { key: 7, text: 'Umidade Diária', value: 7 },
    { key: 8, text: 'Ponto de Orvalho', value: 8 },
    { key: 9, text: 'Pressão', value: 9 },
  ];

  const [option, setOption] = useState({ key: 1, text: 'Temperatura', value: 1 });
  const [graficos, setGraficos] = useState([]);
  const [grafico, setGrafico] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlerOption = (event, target) => {
    setOption(target);
  };

  const getUltimoDiaMes = (ano, mes) => new Date((new Date(ano, mes, 1)) - 1).getDate();

  const montarGraficos = async () => {
    const [ano, mes] = params.data.split('-');

    const optionsGraficoTemperatura = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      chart: { type: 'line' },
      subtitle: { text: `Temperatura por Hora || ${params.data.split('-').reverse().join('/')}` },
      yAxis: { title: { text: 'Temperatura °C' } },
      tooltip: { valueSuffix: '°C' },
      series: [
        { name: 'Temp. Máxima', data: [], color: '#FF0000' },
        { name: 'Temp. Inst.', data: [], color: '#00FF00' },
        { name: 'Temp. Mínima', data: [], color: '#00B6FF' },
        { name: 'Sensação Térmica', data: [], color: '#6a0dad' },
      ],
      xAxis: {
        categories: [],
        title: { text: 'Horas' },
      },
    };

    const optionsGraficoTemperaturaDiaria = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      chart: { type: 'line' },
      subtitle: { text: 'Temperaturas Diárias (Máxima, Média, Mínima)' },
      yAxis: { title: { text: 'Temperatura °C' } },
      tooltip: { valueSuffix: '°C' },
      series: [
        { name: 'Temp. Máxima', data: [], color: '#FF0000' },
        { name: 'Temp. Média Comp.', data: [], color: '#00FF00' },
        { name: 'Temp. Mínima', data: [], color: '#00B6FF' },
      ],
      xAxis: {
        categories: [],
        labels: { step: 1 },
        title: { text: 'Dias do Mês' },
      },
      plotOptions: { series: { pointStart: 1 } },
    };

    const optionsGraficoChuva = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      chart: { type: 'column' },
      subtitle: { text: 'Chuva Acumulada por Hora' },
      yAxis: { title: { text: 'Chuva em mm' } },
      tooltip: { valueSuffix: 'mm' },
      xAxis: {
        categories: [],
        title: { text: 'Horas' },
      },
      series: [{ name: 'Chuva em (mm)', data: [], color: '#00B6FF' }],
    };

    const optionsGraficoChuvaDiaria = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      chart: { type: 'column' },
      subtitle: { text: 'Chuva Acumulada Diária' },
      yAxis: { title: { text: 'Chuva em mm' } },
      tooltip: { valueSuffix: 'mm' },
      xAxis: {
        categories: [],
        labels: { step: 1 },
        title: { text: 'Dias do Mês' },
      },
      plotOptions: { series: { pointStart: 1 } },
      series: [{ name: 'Chuva em (mm)', data: [], color: '#00B6FF' }],
    };

    const optionsGraficoChuvaMensal = {
      chart: { type: 'column' },
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      subtitle: { text: 'Chuva Acumulada Mensal' },
      yAxis: { title: { text: 'Chuva em mm' } },
      tooltip: { valueSuffix: 'mm' },
      series: [{ name: 'Chuva em (mm)', data: [], color: '#00B6FF' }],
      xAxis: {
        categories: [
          `Janeiro/${ano}`,
          `Fevereiro/${ano}`,
          `Março/${ano}`,
          `Abril/${ano}`,
          `Maio/${ano}`,
          `Junho/${ano}`,
          `Julho/${ano}`,
          `Agosto/${ano}`,
          `Setembro/${ano}`,
          `Outubro/${ano}`,
          `Novembro/${ano}`,
          `Dezembro/${ano}`,
        ],
        title: { text: 'Meses' },
      },
    };

    const optionsGraficoUmidade = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      subtitle: { text: 'Umidade por Hora' },
      chart: { type: 'line' },
      yAxis: { title: { text: 'Umidade %' } },
      tooltip: { valueSuffix: '%' },
      series: [
        { name: 'Umidade Máxima', data: [], color: '#FF0000' },
        { name: 'Umidade Inst.', data: [], color: '#00FF00' },
        { name: 'Umidade Mínima', data: [], color: '#00B6FF' },
      ],
      xAxis: {
        categories: [],
        title: { text: 'Horas' },
      },
    };

    const optionsGraficoUmidadeDiaria = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      subtitle: { text: 'Umidades Diárias (Média, Mínima)' },
      chart: { type: 'line' },
      yAxis: { title: { text: 'Umidade %' } },
      tooltip: { valueSuffix: '%' },
      series: [
        { name: 'Umidade Média', data: [], color: '#00FF00' },
        { name: 'Umidade Mínima', data: [], color: '#00B6FF' },
      ],
      xAxis: {
        categories: [],
        labels: { step: 1 },
        title: { text: 'Dias do Mês' },
      },
      plotOptions: { series: { pointStart: 1 } },
    };

    const optionsGraficoOrvalho = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      chart: { type: 'line' },
      subtitle: { text: `Ponto de Orvalho por Hora || ${params.data.split('-').reverse().join('/')}` },
      yAxis: { title: { text: 'Temperatura °C' } },
      tooltip: { valueSuffix: '°C' },
      series: [
        { name: 'Ponto de Orvalho Máxima', data: [], color: '#FF0000' },
        { name: 'Ponto de Orvalho Inst.', data: [], color: '#00FF00' },
        { name: 'Ponto de Orvalho Mínima', data: [], color: '#00B6FF' },
      ],
      xAxis: {
        categories: [],
        title: { text: 'Horas' },
      },
    };

    const optionsGraficoPressao = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      chart: { type: 'line' },
      subtitle: { text: `Pressão por Hora || ${params.data.split('-').reverse().join('/')}` },
      yAxis: { title: { text: 'Pressão (hPa)' } },
      tooltip: { valueSuffix: 'hPa' },
      series: [
        { name: 'Pressão Máxima', data: [], color: '#FF0000' },
        { name: 'Pressão Inst.', data: [], color: '#00FF00' },
        { name: 'Pressão Mínima', data: [], color: '#00B6FF' },
      ],
      xAxis: {
        categories: [],
        title: { text: 'Horas' },
      },
    };

    /* eslint-disable camelcase */
    const vro = btoa(randomstring.generate(50));
    const dec = atob(vro);
    // eslint-disable-next-line
    var decvro = window.btoa(randomstring.generate(50))
    // eslint-disable-next-line
    decvro = decvro.slice(0,-1)
    // eslint-disable-next-line
    const seed = dec + '&' + decvro + vro
    const data_inicio = params.data;
    const data_fim = params.data;
    // eslint-disable-next-line
    window.grecaptcha.ready(function() {
    // eslint-disable-next-line
      window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(token) {
      // eslint-disable-next-line
      const dadosHorarios = await ApiTempo.post('/estacao/front/', { data_inicio, data_fim, estacao : estacao.codigo, seed, gcap: token })
          .then((response) => response.data);
        for (let i = 0; i < dadosHorarios.length; i += 1) {
          const dado = dadosHorarios[i];
          optionsGraficoTemperatura.title.text = `${estacao.entidade} - ${dado.DC_NOME} (${estacao.codigo})`;
          optionsGraficoTemperatura.xAxis.categories.push(dado.HR_MEDICAO?.slice(0, 2));
          optionsGraficoTemperatura.series[0].data.push(parseFloat(dado.TEM_MAX));
          optionsGraficoTemperatura.series[1].data.push(parseFloat(dado.TEM_INS));
          optionsGraficoTemperatura.series[2].data.push(parseFloat(dado.TEM_MIN));
          optionsGraficoTemperatura.series[3].data.push(parseFloat(dado.TEM_SEN));
          optionsGraficoChuva.title.text = `${estacao.entidade} - ${dado.DC_NOME} (${estacao.codigo})`;
          optionsGraficoChuva.xAxis.categories.push(dado.HR_MEDICAO?.slice(0, 2));
          optionsGraficoChuva.series[0].data.push(parseFloat(dado.CHUVA));
          optionsGraficoUmidade.title.text = `${estacao.entidade} - ${dado.DC_NOME} (${estacao.codigo})`;
          optionsGraficoUmidade.xAxis.categories.push(dado.HR_MEDICAO?.slice(0, 2));
          optionsGraficoUmidade.series[0].data.push(parseFloat(dado.UMD_MAX));
          optionsGraficoUmidade.series[1].data.push(parseFloat(dado.UMD_INS));
          optionsGraficoUmidade.series[2].data.push(parseFloat(dado.UMD_MIN));
          optionsGraficoOrvalho.title.text = `${estacao.entidade} - ${dado.DC_NOME} (${estacao.codigo})`;
          optionsGraficoOrvalho.xAxis.categories.push(dado.HR_MEDICAO?.slice(0, 2));
          optionsGraficoOrvalho.series[0].data.push(parseFloat(dado.PTO_MAX));
          optionsGraficoOrvalho.series[1].data.push(parseFloat(dado.PTO_INS));
          optionsGraficoOrvalho.series[2].data.push(parseFloat(dado.PTO_MIN));
          optionsGraficoPressao.title.text = `${estacao.entidade} - ${dado.DC_NOME} (${estacao.codigo})`;
          optionsGraficoPressao.xAxis.categories.push(dado.HR_MEDICAO?.slice(0, 2));
          optionsGraficoPressao.series[0].data.push(parseFloat(dado.PRE_MAX));
          optionsGraficoPressao.series[1].data.push(parseFloat(dado.PRE_INS));
          optionsGraficoPressao.series[2].data.push(parseFloat(dado.PRE_MIN));
        }
      });
    });

    const dataInicio = `${ano}-${mes}-01`;
    const dataFim = `${ano}-${mes}-${getUltimoDiaMes(ano, mes)}`;
    /* eslint-disable camelcase */
    const vro2 = btoa(randomstring.generate(50));
    const dec2 = atob(vro2);
    // eslint-disable-next-line
    var decvro2 = window.btoa(randomstring.generate(50))
    // eslint-disable-next-line
    decvro2 = decvro2.slice(0,-1)
    // eslint-disable-next-line
    const seed2 = dec2 + '&' + decvro2 + vro2
    // eslint-disable-next-line
    window.grecaptcha.ready(function() {
    // eslint-disable-next-line
      window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(token) {
      // eslint-disable-next-line
      const dadosDiarios = await ApiTempo.post('/estacao/diaria/front/', { data_inicio : dataInicio, data_fim : dataFim, estacao : estacao.codigo, seed : seed2, gcap: token })
          .then((response) => response.data);
        for (let i = 0; i < dadosDiarios.length; i += 1) {
          const dado = dadosDiarios[i];
          optionsGraficoTemperaturaDiaria.title.text = `${estacao.entidade} - ${dado.DC_NOME} (${estacao.codigo})`;
          optionsGraficoTemperaturaDiaria.series[0].data.push(parseFloat(dado.TEMP_MAX));
          optionsGraficoTemperaturaDiaria.series[1].data.push(parseFloat(dado.TEMP_MED));
          optionsGraficoTemperaturaDiaria.series[2].data.push(parseFloat(dado.TEMP_MIN));
          optionsGraficoChuvaDiaria.title.text = `${estacao.entidade} - ${dado.DC_NOME} (${estacao.codigo})`;
          optionsGraficoChuvaDiaria.series[0].data.push(parseFloat(dado.CHUVA));
          optionsGraficoUmidadeDiaria.title.text = `${estacao.entidade} - ${dado.DC_NOME} (${estacao.codigo})`;
          optionsGraficoUmidadeDiaria.series[0].data.push(parseFloat(dado.UMID_MED));
          optionsGraficoUmidadeDiaria.series[1].data.push(parseFloat(dado.UMID_MIN));
        }
      });
    });
    /* eslint-disable camelcase */
    const vro3 = btoa(randomstring.generate(50));
    const dec3 = atob(vro3);
    // eslint-disable-next-line
    var decvro3 = window.btoa(randomstring.generate(50))
    // eslint-disable-next-line
    decvro3 = decvro3.slice(0,-1)
    // eslint-disable-next-line
    const seed3 = dec3 + '&' + decvro3 + vro3
    const dataInicioc = `${ano}-01-01`;
    const dataFimc = `${ano}-${mes}-${getUltimoDiaMes(ano, mes)}`;
    // eslint-disable-next-line
    window.grecaptcha.ready(function() {
    // eslint-disable-next-line
      window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(token) {
      // eslint-disable-next-line
      const chuvaMensal = await ApiTempo.post('/estacao/front/', { data_inicio : dataInicioc, data_fim : dataFimc, estacao : estacao.codigo, seed : seed3, gcap: token })
          .then((response) => response.data);
        const dados = [...new Array(12).fill(0)];
        for (let i = 0; i < chuvaMensal.length; i += 1) {
          const tete = chuvaMensal[i];
          if (tete.DT_MEDICAO && tete.CHUVA) {
            const index = parseInt(tete.DT_MEDICAO.split('-')[1], 10) - 1;
            dados[index] += parseFloat(tete.CHUVA);
          }
        }
        for (let i = 0; i < dados.length; i += 1) {
          optionsGraficoChuvaMensal.title.text = `${estacao.entidade} - ${estacao.codigo}`;
          optionsGraficoChuvaMensal.series[0].data.push(parseFloat(dados[i].toFixed(2)));
        }
        setGraficos([
          optionsGraficoTemperatura,
          optionsGraficoTemperaturaDiaria,
          optionsGraficoChuva,
          optionsGraficoChuvaDiaria,
          optionsGraficoChuvaMensal,
          optionsGraficoUmidade,
          optionsGraficoUmidadeDiaria,
          optionsGraficoOrvalho,
          optionsGraficoPressao,
        ]);
      });
    });
  };

  useEffect(() => {
    montarGraficos();
  }, []);

  useEffect(() => {
    if ((graficos.length !== 0 && option) || loading) {
      setGrafico(graficos[option.value - 1]);
    }
  }, [graficos, loading]);

  useEffect(() => {
    if (grafico) {
      setLoading(false);
    }
  }, [grafico]);

  useEffect(() => {
    setLoading(true);
  }, [option]);

  if (!loading) {
    return (
      <div className="content">
        <Dropdown
          loading={options?.length === 0}
          value={option.value}
          onChange={handlerOption}
          options={options}
          simple
          item
          className="dropdown-grafico"
        />
        {grafico
          ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={grafico}
              containerProps={{ className: 'graficoContainer' }}
            />
          )
          : null}
      </div>
    );
  }

  return <Loading />;
}

export default Automaticas;
