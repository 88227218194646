/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import { useVisibleSidebar } from '../../../contexts/VisibleSidebar';
import ApiTempo from '../../../services/ApiTempo';

function GraficosAnuais() {
  const params = useParams();

  const estados = [
    { key: null, value: null, text: 'Todos' },
    { key: 'AC', value: 'AC', text: 'Acre' },
    { key: 'AL', value: 'AL', text: 'Alagoas' },
    { key: 'AP', value: 'AP', text: 'Amapá' },
    { key: 'AM', value: 'AM', text: 'Amazonas' },
    { key: 'BA', value: 'BA', text: 'Bahia' },
    { key: 'CE', value: 'CE', text: 'Ceará' },
    { key: 'DF', value: 'DF', text: 'Distrito Federal' },
    { key: 'ES', value: 'ES', text: 'Espírito Santo' },
    { key: 'GO', value: 'GO', text: 'Goiás' },
    { key: 'MA', value: 'MA', text: 'Maranhão' },
    { key: 'MT', value: 'MT', text: 'Mato Grosso' },
    { key: 'MS', value: 'MS', text: 'Mato Grosso do Sul' },
    { key: 'MG', value: 'MG', text: 'Minas Gerais' },
    { key: 'PA', value: 'PA', text: 'Pará' },
    { key: 'PB', value: 'PB', text: 'Paraíba' },
    { key: 'PR', value: 'PR', text: 'Paraná' },
    { key: 'PE', value: 'PE', text: 'Pernambuco' },
    { key: 'PI', value: 'PI', text: 'Piauí' },
    { key: 'RJ', value: 'RJ', text: 'Rio de Janeiro' },
    { key: 'RN', value: 'RN', text: 'Rio Grande do Norte' },
    { key: 'RS', value: 'RS', text: 'Rio Grande do Sul' },
    { key: 'RO', value: 'RO', text: 'Rondônia' },
    { key: 'RR', value: 'RR', text: 'Roraima' },
    { key: 'SC', value: 'SC', text: 'Santa Catarina' },
    { key: 'SP', value: 'SP', text: 'São Paulo' },
    { key: 'SE', value: 'SE', text: 'Sergipe' },
    { key: 'TO', value: 'TO', text: 'Tocantins' },
  ];

  const anos = [...Array(10)].map((item, index) => {
    const ano = new Date(new Date().setFullYear(new Date().getFullYear() - index)).getFullYear();
    return { key: ano, text: ano, value: ano };
  });

  const [ano, setAno] = useState(new Date().getFullYear());
  const [errorAno, setErrorAno] = useState(null);
  const [errorEstacao, setErrorEstacao] = useState(false);
  const [estacoes, setEstacoes] = useState([]);
  const [estacaoSelecionada, setEstacaoSelecionada] = useState(null);
  const [estadoSelecionado, setEstadoSelecionado] = useState(null);

  const [, setSubmit] = useSubmit();
  const [submitPost, setSubmitPost] = useSubmitPost();
  const [, setVisibleSidebar] = useVisibleSidebar();

  const validateForm = () => {
    if (!estacaoSelecionada) {
      setErrorEstacao(true);
      return false;
    }

    if (!ano) {
      setErrorAno(true);
      return false;
    }

    setErrorEstacao(false);
    return true;
  };

  const handlerAno = (event, selected) => {
    setSubmit(false);
    setErrorAno(false);
    setAno(selected.value);
  };

  const handlerSubmit = () => {
    setSubmitPost({ estacao: estacaoSelecionada, ano });
  };

  useEffect(() => {
    ApiTempo.get('/estacoes/T')
      .then((response) => {
        setEstacoes(() => response.data.map((item) => ({
          key: item.CD_ESTACAO,
          text: `${item.DC_NOME} (${item.CD_ESTACAO})`,
          value: `${item.CD_ESTACAO}::${item.DC_NOME}`,
          estado: item.SG_ESTADO,
        })));
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao carregar lista de estações.");
      });
  }, []);

  useEffect(() => {
    const validate = validateForm();
    if (validate) {
      setSubmit(true);
      setVisibleSidebar(false);
    }

    if (Object.keys(params).length !== 0 && Object.keys(submitPost).length !== 0 && 'estacao' in submitPost && submitPost.estacao) {
      setEstacaoSelecionada(submitPost.estacao);
      setSubmit(true);
      setVisibleSidebar(false);
    }
  }, [submitPost]);

  const handlerEstacao = (event, selected) => {
    setSubmit(false);
    setErrorEstacao(false);
    setEstacaoSelecionada(selected.value);
  };

  const handlerEstado = (event, selected) => {
    setSubmit(false);
    setEstacaoSelecionada(null);
    setEstadoSelecionado(selected.value);
  };

  return (
    <>
      <span className="sidebar-item-label">Estado</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={estados}
        onChange={handlerEstado}
        value={estadoSelecionado}
        loading={estados.length === 0}
        style={{ marginBottom: 15 }}
      />

      <span className="sidebar-item-label">Estação</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={estadoSelecionado
          ? estacoes.filter((item) => item.estado === estadoSelecionado)
          : estacoes}
        onChange={handlerEstacao}
        value={estacaoSelecionada}
        loading={estacoes.length === 0}
        style={{ marginBottom: 15 }}
        error={errorEstacao}
      />

      <span className="sidebar-item-label">Ano</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={anos}
        onChange={handlerAno}
        value={ano}
        loading={anos?.length === 0}
        style={{ marginBottom: 15 }}
        error={errorAno}
      />

      <Button onClick={handlerSubmit}>Gerar Gráficos</Button>
    </>
  );
}

export default GraficosAnuais;
