import React, { useState, useEffect } from 'react';
import {
  Image, Button, Icon,
} from 'semantic-ui-react';
import { useProduto } from '../../../contexts/Produto';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import ApiTempo from '../../../services/ApiTempo';
import Loading from '../../Loading/Index';
import './Style.css';

function Sondagem() {
  const [imagem, setImagem] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [produto] = useProduto();
  const [submit] = useSubmit();
  const [submitPost] = useSubmitPost();

  const getImagem = async () => {
    await ApiTempo.get(`/Sondagem/${submitPost.estacao}/${submitPost.data}/${submitPost.hora}`)
      .then((response) => setImagem(response.data.base64))
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao buscar imagem.");
      });
  };

  useEffect(() => {
    getImagem();
  }, [submitPost]);

  const handlerFullscreen = () => {
    if (!fullscreen) {
      const element = document.getElementById('content-fullscreen');
      const tmp = (
        element.mozRequestFullScreen
        || element.webkitRequestFullscreen // Safari
        || element.requestFullscreen // IE11
      );
      tmp.call(element);
    } else {
      document.exitFullscreen();
    }

    setFullscreen(!fullscreen);
  };

  if (produto === 'Sondagem' && submit && Object.keys(submitPost) !== 0) {
    return (
      <div className="content" id="content-fullscreen">
        <Image src={imagem} size={fullscreen ? 'huge' : 'big'} rounded />

        <Button icon size="massive" onClick={handlerFullscreen} className="fullscreen-button">
          <Icon name={fullscreen ? 'compress' : 'expand'} />
        </Button>
      </div>
    );
  }

  return <Loading />;
}
export default Sondagem;
