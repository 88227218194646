/* eslint-disable react/jsx-props-no-spreading */
import React, {
  createContext, useContext, useState, useMemo,
} from 'react';

const Submit = createContext();

function useSubmit() {
  const context = useContext(Submit);

  if (!context) {
    // eslint-disable-next-line no-alert
    window.alert('Ocorreu um erro ao submeter o formulário, tente atualizar a página.');
  }

  return context;
}

function SubmitProvider(props) {
  const [submitEnviado, setSubmitEnviado] = useState(false);
  const value = useMemo(() => [submitEnviado, setSubmitEnviado], [submitEnviado]);
  return <Submit.Provider value={value} {...props} />;
}

export { useSubmit, SubmitProvider };
