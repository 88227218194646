import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import { useVisibleSidebar } from '../../../contexts/VisibleSidebar';
import ApiTempo from '../../../services/ApiTempo';

function Sondagem() {
  const params = useParams();

  const [estacoes, setEstacoes] = useState([]);
  const [datas, setDatas] = useState([]);
  const [horas, setHoras] = useState([]);

  const [estacaoSelecionada, setEstacaoSelecionada] = useState(null);
  const [dataSelecionada, setDataSelecionada] = useState(null);
  const [horaSelecionada, setHoraSelecionada] = useState(null);

  const [errorEstacao, setErrorEstacao] = useState(false);
  const [errorData, setErrorData] = useState(false);
  const [errorHora, setErrorHora] = useState(false);

  const [, setSubmit] = useSubmit();
  const [submitPost, setSubmitPost] = useSubmitPost();
  const [, setVisibleSidebar] = useVisibleSidebar();

  const validateForm = () => {
    if (!estacaoSelecionada) {
      setErrorEstacao(true);
      return false;
    }

    if (!dataSelecionada) {
      setErrorData(true);
      return false;
    }

    if (!horaSelecionada) {
      setErrorHora(true);
      return false;
    }

    setErrorEstacao(false);
    setErrorData(false);
    setErrorHora(false);
    return true;
  };

  const handlerSubmit = () => {
    setSubmitPost({ data: dataSelecionada, estacao: estacaoSelecionada, hora: horaSelecionada });
  };

  const getEstacoes = async () => {
    await ApiTempo.get('/estacoesSondagem')
      .then((response) => {
        setEstacoes(() => response.data.map((item) => ({
          key: item.sigla,
          text: item.nome,
          value: item.sigla,
        })));
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao carregar lista de estações.");
      });
  };

  const getDatas = async () => {
    await ApiTempo.get(`/datas/Sondagem/${estacaoSelecionada}`)
      .then((response) => {
        setDatas(() => response.data.map((item) => ({
          key: item.sigla,
          text: item.nome,
          value: item.sigla,
        })));
      })
      .catch((error) => {
      // eslint-disable-next-line
      console.log(error);
        // eslint-disable-next-line
      window.alert("Erro ao carregar lista de datas.");
      });
  };

  const getHoras = async () => {
    await ApiTempo.get(`/horas/Sondagem/${estacaoSelecionada}/${dataSelecionada}`)
      .then((response) => {
        setHoras(() => response.data.map((item) => ({
          key: item.sigla,
          text: item.nome,
          value: item.sigla,
        })));
      })
      .catch((error) => {
      // eslint-disable-next-line
      console.log(error);
        // eslint-disable-next-line
      window.alert("Erro ao carregar lista de horas.");
      });
  };

  useEffect(() => {
    getEstacoes();
  }, []);

  useEffect(() => {
    if (estacaoSelecionada) {
      setDatas([]);
      setHoras([]);
      getDatas();
    }
  }, [estacaoSelecionada]);

  useEffect(() => {
    if (dataSelecionada) {
      setHoras([]);
      getHoras();
    }
  }, [dataSelecionada]);

  useEffect(() => {
    const validate = validateForm();
    if (validate) {
      setSubmit(true);
      setVisibleSidebar(false);
    }

    if (Object.keys(params).length !== 0
      && Object.keys(submitPost).length !== 0
      && 'data' in submitPost
      && 'hora' in submitPost
      && 'estacao' in submitPost) {
      setEstacaoSelecionada(submitPost.estacao);
      setDataSelecionada(submitPost.data);
      setHoraSelecionada(submitPost.hora);
      setSubmit(true);
      setVisibleSidebar(false);
    }
  }, [submitPost]);

  const handlerEstacao = (event, selected) => {
    setErrorEstacao(false);
    setEstacaoSelecionada(selected.value);
  };

  const handlerData = (event, selected) => {
    setErrorData(false);
    setDataSelecionada(selected.value);
  };

  const handlerHora = (event, selected) => {
    setErrorHora(false);
    setHoraSelecionada(selected.value);
  };

  return (
    <>
      <span className="sidebar-item-label">Estação</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={estacoes}
        onChange={handlerEstacao}
        value={estacaoSelecionada}
        loading={estacoes.length === 0}
        style={{ marginBottom: 15 }}
        error={errorEstacao}
      />

      <span className="sidebar-item-label">Data</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={datas}
        onChange={handlerData}
        value={dataSelecionada}
        loading={estacoes.length === 0}
        style={{ marginBottom: 15 }}
        error={errorData}
      />

      <span className="sidebar-item-label">Hora</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={horas}
        onChange={handlerHora}
        value={horaSelecionada}
        loading={horas.length === 0}
        style={{ marginBottom: 15 }}
        error={errorHora}
      />

      <Button onClick={handlerSubmit}>Gerar Imagem</Button>
    </>
  );
}

export default Sondagem;
