import React from 'react';
import { Image } from 'semantic-ui-react';
import Loading from '../../Assets/Loading.gif';
import './Style.css';

function Index() {
  return (
    <div className="content-loading">
      <div className="loading-bg">
        <Image src={Loading} size="small" />
        <div className="loading-text">Carregando</div>
      </div>
    </div>
  );
}

export default Index;
