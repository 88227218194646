/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import {
  Menu, Sidebar, Dropdown, Segment,
} from 'semantic-ui-react';
import { useProduto } from '../../contexts/Produto';
import { useSubmit } from '../../contexts/Submit';
import { useSubmitPost } from '../../contexts/SubmitPost';
import { useVisibleSidebar } from '../../contexts/VisibleSidebar';
import ApiTempo from '../../services/ApiTempo';
import AnaliseSituacaoAtual from './Analise_Sinotica/Index';
import CondicoesTempoRegistradas from './Condicoes_Tempo/Index';
import GraficosDiarios from './Graficos_Horarios/Index';
import Graficos from './Graficos_Diarios/Index';
import Content from '../Content/Index';
import GraficosAnuais from './Graficos_Anuais/Index';
import CondicoesRegistradas from './Mapa_Condicoes/Index';
import Anomalias from './Anomalia_Diaria/Index';
import PrecAcumulada from './Mapa_Precipitacao/Index';
import Sondagem from './Sondagem/Index';
import TabelaEstacoes from './Tabela_Estacoes/Index';
import ValoresExtremos from './Valores_Extremos/Index';
import WSI from './Wigos/Index';

import './Style.css';

function MenuSidebar() {
  const ComponentsMenu = {
    AnaliseSituacaoAtual,
    CondicoesTempoRegistradas,
    GraficosDiarios,
    Graficos,
    GraficosAnuais,
    CondicoesRegistradas,
    Anomalias,
    PrecAcumulada,
    Sondagem,
    TabelaEstacoes,
    ValoresExtremos,
    WSI,
  };
  const [produto, setProduto] = useProduto();
  const [submit, setSubmit] = useSubmit();
  const [, setSubmitPost] = useSubmitPost();
  const [visible, setVisible] = useVisibleSidebar();
  const [optionsProdutos, setOptionsProdutos] = useState([]);

  const ComponentMenuRender = ComponentsMenu[produto] || null;

  const getProdutos = async () => {
    await ApiTempo.get('/produtos')
      .then((response) => {
        setOptionsProdutos(() => response.data.map((item) => ({
          key: item.sigla,
          text: item.nome,
          value: item.sigla,
        })));
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao carregar lista de produtos.");
      });
  };

  useEffect(() => {
    getProdutos();
  }, []);

  const handlerProduto = (event, data) => setProduto(data.value);

  useEffect(() => {
    if (produto && submit) {
      setSubmitPost({});
      setSubmit(false);
    }
  }, [produto]);

  return (
    <Sidebar.Pushable className="sidebar-content">
      <Sidebar
        as={Menu}
        animation="overlay"
        onHide={() => setVisible(false)}
        vertical
        visible={visible}
      >
        <Menu.Item className="sidebar-item">
          <span className="sidebar-item-label">Produto</span>
          <Dropdown
            placeholder="Selecione"
            search
            selection
            options={optionsProdutos}
            onChange={handlerProduto}
            value={produto}
            loading={optionsProdutos.length === 0}
          />
        </Menu.Item>

        {ComponentMenuRender ? <Menu.Item className="sidebar-item"><ComponentMenuRender /></Menu.Item> : null}
      </Sidebar>

      <Sidebar.Pusher dimmed={visible} className="wrapped-content">
        <Segment basic style={{ width: '97%' }}>
          <Content />
        </Segment>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
}

export default MenuSidebar;
