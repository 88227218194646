import React, { useState, useEffect } from 'react';
import { Tab } from 'semantic-ui-react';
import atob from 'atob';
import btoa from 'btoa';
import randomstring from 'randomstring';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useProduto } from '../../../contexts/Produto';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import ApiTempo from '../../../services/ApiTempo';
import Loading from '../../Loading/Index';

require('highcharts/modules/exporting')(Highcharts);

function GraficosAnuais() {
  const [tabs, setTabs] = useState([]);
  const [produto] = useProduto();
  const [submit] = useSubmit();
  const [submitPost] = useSubmitPost();

  const criarTabs = async (dados) => {
    const [codigo, nome] = submitPost.estacao.split('::');

    const optionsGraficoTemperatura = {
      title: { text: `Estação: ${nome} (${codigo})` },
      series: [{
        name: 'Temperatura',
        data: [],
        tooltip: { valueDecimals: 2 },
      }],
    };

    const optionsGraficoUmidade = {
      title: { text: `Estação: ${nome} (${codigo})` },
      series: [{
        name: 'Umidade',
        data: [],
        tooltip: { valueDecimals: 2 },
      }],
    };

    const optionsGraficoPressao = {
      title: { text: `Estação: ${nome} (${codigo})` },
      series: [{
        name: 'Pressão',
        data: [],
        tooltip: { valueDecimals: 2 },
      }],
    };

    const optionsGraficoPto = {
      title: { text: `Estação: ${nome} (${codigo})` },
      series: [{
        name: 'Ponto de Orvalho',
        data: [],
        tooltip: { valueDecimals: 2 },
      }],
    };

    const optionsGraficoRadiacao = {
      title: { text: `Estação: ${nome} (${codigo})` },
      series: [{
        name: 'Radiação',
        data: [],
        tooltip: { valueDecimals: 2 },
      }],
    };

    const optionsGraficoPrecipitacao = {
      title: { text: `Estação: ${nome} (${codigo})` },
      series: [{
        name: 'Precipitação',
        data: [],
        tooltip: { valueDecimals: 2 },
      }],
    };

    const optionsGraficoVelVento = {
      title: { text: `Estação: ${nome} (${codigo})` },
      series: [{
        name: 'Velocidade do Vento',
        data: [],
        tooltip: { valueDecimals: 2 },
      }],
    };

    for (let i = 0; i < dados.length; i += 1) {
      const dado = dados[i];

      const [ano, mes, dia] = dado.DT_MEDICAO.split('-');
      const hora = parseInt(dado.HR_MEDICAO.substring(0, 2), 10);
      const time = Date.UTC(ano, parseInt(mes, 10) - 1, dia, hora, 0, 0);

      optionsGraficoTemperatura.series[0].data.push(
        dado.TEM_INS ? [time, parseFloat(dado.TEM_INS)] : [time, null],
      );
      optionsGraficoUmidade.series[0].data.push(
        dado.UMD_INS ? [time, parseFloat(dado.UMD_INS)] : [time, null],
      );
      optionsGraficoPressao.series[0].data.push(
        dado.PRE_INS ? [time, parseFloat(dado.PRE_INS)] : [time, null],
      );
      optionsGraficoPto.series[0].data.push(
        dado.PTO_INS ? [time, parseFloat(dado.PTO_INS)] : [time, null],
      );
      optionsGraficoRadiacao.series[0].data.push(
        dado.RAD_GLO ? [time, parseFloat(dado.RAD_GLO)] : [time, null],
      );
      optionsGraficoPrecipitacao.series[0].data.push(
        dado.CHUVA ? [time, parseFloat(dado.CHUVA)] : [time, null],
      );
      optionsGraficoVelVento.series[0].data.push(
        dado.VEN_VEL ? [time, parseFloat(dado.VEN_VEL)] : [time, null],
      );
    }

    setTabs([{
      menuItem: 'Temperatura',
      pane: {
        key: Math.random(),
        content:
  <HighchartsReact
    highcharts={Highcharts}
    options={optionsGraficoTemperatura}
    constructorType="stockChart"
  />,
      },
    }, {
      menuItem: 'Umidade',
      pane: {
        key: Math.random(),
        content:
  <HighchartsReact
    highcharts={Highcharts}
    options={optionsGraficoUmidade}
    constructorType="stockChart"
  />,
      },
    }, {
      menuItem: 'Pressão',
      pane: {
        key: Math.random(),
        content:
  <HighchartsReact
    highcharts={Highcharts}
    options={optionsGraficoPressao}
    constructorType="stockChart"
  />,
      },
    }, {
      menuItem: 'Ponto de Orvalho',
      pane: {
        key: Math.random(),
        content:
  <HighchartsReact
    highcharts={Highcharts}
    options={optionsGraficoPto}
    constructorType="stockChart"
  />,
      },
    }, {
      menuItem: 'Radiação',
      pane: {
        key: Math.random(),
        content:
  <HighchartsReact
    highcharts={Highcharts}
    options={optionsGraficoRadiacao}
    constructorType="stockChart"
  />,
      },
    }, {
      menuItem: 'Precipitacao',
      pane: {
        key: Math.random(),
        content:
  <HighchartsReact
    highcharts={Highcharts}
    options={optionsGraficoPrecipitacao}
    constructorType="stockChart"
  />,
      },
    }, {
      menuItem: 'Velocidade do Vento',
      pane: {
        key: Math.random(),
        content:
  <HighchartsReact
    highcharts={Highcharts}
    options={optionsGraficoVelVento}
    constructorType="stockChart"
  />,
      },
    }]);
  };

  const getDados = async () => {
    if ('estacao' in submitPost && 'ano' in submitPost) {
      const codigo = submitPost.estacao.split('::')[0];
      const dataInicial = `${submitPost.ano}-01-01`;
      const dataFinal = `${submitPost.ano}-12-01`;
      /* eslint-disable camelcase */
      const vro = btoa(randomstring.generate(50));
      const dec = atob(vro);
      // eslint-disable-next-line
      var decvro = window.btoa(randomstring.generate(50))
      // eslint-disable-next-line
      decvro = decvro.slice(0,-1)
      // eslint-disable-next-line
      const seed = dec + '&' + decvro + vro
      // eslint-disable-next-line
      const data_inicio = dataInicial;
      const data_fim = dataFinal;
      const estacao = codigo;
      // eslint-disable-next-line
      window.grecaptcha.ready(function() {
      // eslint-disable-next-line
        window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(token) {
        // eslint-disable-next-line
          await ApiTempo.post('/estacao/front/', { data_inicio, data_fim, estacao, seed, gcap: token })
            .then((response) => criarTabs(response.data))
            .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
              // eslint-disable-next-line
            window.alert("Erro ao buscar dados das estações.");
            });
        });
      });
    }
  };

  useEffect(() => {
    getDados();
  }, [submitPost]);

  if (produto === 'GraficosAnuais' && submit && Object.keys(submitPost) !== 0 && tabs.length !== 0) {
    return (
      <div>
        <span className="titulo-data">
          {/* eslint-disable-next-line */}
          *Dados disponíveis em tempo real (sem controle de qualidade).
        </span>
        <Tab
          menu={{ pointing: true, className: 'wrapped-tab' }}
          panes={tabs}
          renderActiveOnly={false}
        />
      </div>
    );
  }
  return <Loading />;
}
export default GraficosAnuais;
