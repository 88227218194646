import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import { useVisibleSidebar } from '../../../contexts/VisibleSidebar';
import ApiTempo from '../../../services/ApiTempo';

function Graficos() {
  const params = useParams();

  const estados = [
    { key: null, value: null, text: 'Todos' },
    { key: 'AC', value: 'AC', text: 'Acre' },
    { key: 'AL', value: 'AL', text: 'Alagoas' },
    { key: 'AP', value: 'AP', text: 'Amapá' },
    { key: 'AM', value: 'AM', text: 'Amazonas' },
    { key: 'BA', value: 'BA', text: 'Bahia' },
    { key: 'CE', value: 'CE', text: 'Ceará' },
    { key: 'DF', value: 'DF', text: 'Distrito Federal' },
    { key: 'ES', value: 'ES', text: 'Espírito Santo' },
    { key: 'GO', value: 'GO', text: 'Goiás' },
    { key: 'MA', value: 'MA', text: 'Maranhão' },
    { key: 'MT', value: 'MT', text: 'Mato Grosso' },
    { key: 'MS', value: 'MS', text: 'Mato Grosso do Sul' },
    { key: 'MG', value: 'MG', text: 'Minas Gerais' },
    { key: 'PA', value: 'PA', text: 'Pará' },
    { key: 'PB', value: 'PB', text: 'Paraíba' },
    { key: 'PR', value: 'PR', text: 'Paraná' },
    { key: 'PE', value: 'PE', text: 'Pernambuco' },
    { key: 'PI', value: 'PI', text: 'Piauí' },
    { key: 'RJ', value: 'RJ', text: 'Rio de Janeiro' },
    { key: 'RN', value: 'RN', text: 'Rio Grande do Norte' },
    { key: 'RS', value: 'RS', text: 'Rio Grande do Sul' },
    { key: 'RO', value: 'RO', text: 'Rondônia' },
    { key: 'RR', value: 'RR', text: 'Roraima' },
    { key: 'SC', value: 'SC', text: 'Santa Catarina' },
    { key: 'SP', value: 'SP', text: 'São Paulo' },
    { key: 'SE', value: 'SE', text: 'Sergipe' },
    { key: 'TO', value: 'TO', text: 'Tocantins' },
  ];

  const meses = [
    { key: 1, text: 'Janeiro', value: '01' },
    { key: 2, text: 'Fevereiro', value: '02' },
    { key: 3, text: 'Março', value: '03' },
    { key: 4, text: 'Abril', value: '04' },
    { key: 5, text: 'Maio', value: '05' },
    { key: 6, text: 'Junho', value: '06' },
    { key: 7, text: 'Julho', value: '07' },
    { key: 8, text: 'Agosto', value: '08' },
    { key: 9, text: 'Setembro', value: '09' },
    { key: 10, text: 'Outubro', value: '10' },
    { key: 11, text: 'Novembro', value: '11' },
    { key: 12, text: 'Dezembro', value: '12' },
  ];

  const anos = [...Array(10)].map((item, index) => {
    const ano = new Date(new Date().setFullYear(new Date().getFullYear() - index)).getFullYear();
    return { key: ano, text: ano, value: ano };
  });

  const [, setSubmit] = useSubmit();
  const [submitPost, setSubmitPost] = useSubmitPost();
  const [, setVisibleSidebar] = useVisibleSidebar();

  const [estacoes, setEstacoes] = useState([]);
  const [estacoesAutomaticas, setEstacoesAutomaticas] = useState([]);
  const [estacoesConvencionais, setEstacoesConvencionais] = useState([]);
  const [tipoEstacao, setTipoEstacao] = useState('T');
  const [mes, setMes] = useState((`0${new Date().getMonth() + 1}`).slice(-2));
  const [ano, setAno] = useState(new Date().getFullYear());
  const [estacao, setEstacao] = useState(null);
  const [estadoSelecionado, setEstadoSelecionado] = useState(null);

  const [errorMes, setErrorMes] = useState(null);
  const [errorAno, setErrorAno] = useState(null);
  const [errorEstacao, setErrorEstacao] = useState(null);

  const handlerEstacao = (event, selected) => {
    setSubmit(false);
    setErrorEstacao(false);
    setEstacao(selected.value);
  };

  const handlerMes = (event, selected) => {
    setSubmit(false);
    setErrorMes(false);
    setMes(selected.value);
  };

  const handlerAno = (event, selected) => {
    setSubmit(false);
    setErrorAno(false);
    setAno(selected.value);
  };

  const handlerSubmit = () => {
    setSubmitPost({
      tipoEstacao, estacao, mes, ano,
    });
  };

  const validateForm = () => {
    if (!estacao) {
      setErrorEstacao(true);
      return false;
    }

    if (!mes) {
      setErrorMes(true);
      return false;
    }

    if (!ano) {
      setErrorAno(true);
      return false;
    }

    setErrorEstacao(false);
    setErrorMes(false);
    setErrorAno(false);
    return true;
  };

  useEffect(() => {
    setSubmit(false);
    setEstacao(null);
    setEstacoes(tipoEstacao === 'T' ? estacoesAutomaticas : estacoesConvencionais);
  }, [tipoEstacao]);

  const getEstacoes = async (tipo) => {
    await ApiTempo.get(`/estacoes/${tipo}`)
      .then((response) => {
        const tempEstacoes = response.data.map((item) => ({
          key: item.CD_ESTACAO,
          text: `${item.DC_NOME} (${item.CD_ESTACAO})`,
          value: `${item.CD_ESTACAO}::${item.DC_NOME}`,
          estado: item.SG_ESTADO,
        }));

        if (tipo === 'T') {
          setEstacoesAutomaticas(tempEstacoes);
          setEstacoes(tempEstacoes);
        }

        if (tipo === 'M') {
          setEstacoesConvencionais(tempEstacoes);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao carregar lista de estações.");
      });
  };

  const handlerEstado = (event, selected) => {
    setEstacao(null);
    setEstadoSelecionado(selected.value);
  };

  useEffect(() => {
    getEstacoes('T');
    getEstacoes('M');
  }, []);

  useEffect(() => {
    const validate = validateForm();
    if (validate && Object.keys(submitPost).length !== 0) {
      setSubmit(true);
      setVisibleSidebar(false);
    }

    if (Object.keys(params).length !== 0
      && Object.keys(submitPost).length !== 0
      && 'estacao' in submitPost && submitPost.estacao
      && 'tipoEstacao' in submitPost && submitPost.estacao
      && 'ano' in submitPost && submitPost.estacao
      && 'mes' in submitPost && submitPost.estacao
    ) {
      setTipoEstacao(submitPost.tipoEstacao);
      setEstacao(submitPost.estacao);
      setAno(submitPost.ano);
      setMes(submitPost.mes);
      setSubmit(true);
      setVisibleSidebar(false);
    }
  }, [submitPost]);

  return (
    <>
      <span className="sidebar-item-label">Tipo de Estação</span>
      <Button.Group compact style={{ marginBottom: 15 }}>
        <Button
          key="T"
          onClick={() => setTipoEstacao('T')}
          className={tipoEstacao === 'T' ? 'btn-active' : ''}
        >
          Automáticas
        </Button>
        <Button
          key="M"
          onClick={() => setTipoEstacao('M')}
          className={tipoEstacao === 'M' ? 'btn-active' : ''}
        >
          Convencionais
        </Button>
      </Button.Group>

      <span className="sidebar-item-label">Estado</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={estados}
        onChange={handlerEstado}
        value={estadoSelecionado}
        loading={estados.length === 0}
        style={{ marginBottom: 15 }}
      />

      <span className="sidebar-item-label">Estação</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={estadoSelecionado
          ? estacoes.filter((item) => item.estado === estadoSelecionado)
          : estacoes}
        onChange={handlerEstacao}
        value={estacao}
        loading={estacao?.length === 0}
        style={{ marginBottom: 15 }}
        error={errorEstacao}
      />

      <span className="sidebar-item-label">Mês</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={meses}
        onChange={handlerMes}
        value={mes}
        loading={meses?.length === 0}
        style={{ marginBottom: 15 }}
        error={errorMes}
      />

      <span className="sidebar-item-label">Ano</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={anos}
        onChange={handlerAno}
        value={ano}
        loading={anos?.length === 0}
        style={{ marginBottom: 15 }}
        error={errorAno}
      />

      <Button onClick={handlerSubmit}>Gerar Gráficos</Button>
    </>
  );
}

export default Graficos;
