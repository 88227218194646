import React, { useState, useEffect } from 'react';
import { Tab } from 'semantic-ui-react';
import atob from 'atob';
import btoa from 'btoa';
import randomstring from 'randomstring';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useProduto } from '../../../contexts/Produto';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import ApiTempo from '../../../services/ApiTempo';
import Loading from '../../Loading/Index';

import './Style.css';

function GraficosDiarios() {
  const [tabs, setTabs] = useState([]);
  const [produto] = useProduto();
  const [submit] = useSubmit();
  const [submitPost] = useSubmitPost();

  const criarTabs = (dados) => {
    const [codigo, nome] = submitPost.estacao.split('::');

    const optionsGraficoTemperatura = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      subtitle: { text: `Temperatura por Hora || Estação: ${nome} (${codigo}) - ${submitPost.data.split('-').reverse().join('/')}` },
      yAxis: { title: { text: 'Temperatura °C' } },
      tooltip: { valueSuffix: '°C' },
      series: [
        { name: 'Temp. Máxima', data: [], color: '#FF0000' },
        { name: 'Temp. Inst.', data: [], color: '#00FF00' },
        { name: 'Temp. Mínima', data: [], color: '#00B6FF' },
      ],
      xAxis: { categories: [] },
    };

    const optionsGraficoUmidade = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      subtitle: { text: `Umidade por Hora || Estação: ${nome} (${codigo}) - ${submitPost.data.split('-').reverse().join('/')}` },
      yAxis: { title: { text: 'Umidade %' } },
      tooltip: { valueSuffix: '%' },
      series: [
        { name: 'Umidade Máxima', data: [], color: '#FF0000' },
        { name: 'Umidade Inst.', data: [], color: '#00FF00' },
        { name: 'Umidade Mínima', data: [], color: '#00B6FF' },
      ],
      xAxis: { categories: [] },
    };

    const optionsGraficoOrvalho = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      subtitle: { text: `Ponto de Orvalho por Hora || Estação: ${nome} (${codigo}) - ${submitPost.data.split('-').reverse().join('/')}` },
      yAxis: { title: { text: 'Temperatura °C' } },
      tooltip: { valueSuffix: '°C' },
      series: [
        { name: 'Pto. Orvalho Máxima', data: [], color: '#FF0000' },
        { name: 'Pto. Orvalho Inst.', data: [], color: '#00FF00' },
        { name: 'Pto. Orvalho Mínima', data: [], color: '#00B6FF' },
      ],
      xAxis: { categories: [] },
    };

    const optionsGraficoPressao = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      subtitle: { text: `Pressão por Hora || Estação: ${nome} (${codigo}) - ${submitPost.data.split('-').reverse().join('/')}` },
      yAxis: { title: { text: 'Pressão (hPa)' } },
      tooltip: { valueSuffix: 'hPa' },
      series: [
        { name: 'Pressão Máxima', data: [], color: '#FF0000' },
        { name: 'Pressão Inst.', data: [], color: '#00FF00' },
        { name: 'Pressão Mínima', data: [], color: '#00B6FF' },
      ],
      xAxis: { categories: [] },
    };

    const optionsGraficoChuva = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      subtitle: { text: `Chuva Acumulada por Hora || Estação: ${nome} (${codigo}) - ${submitPost.data.split('-').reverse().join('/')}` },
      chart: { type: 'column' },
      yAxis: { title: { text: 'Chuva em (mm)' } },
      tooltip: { valueSuffix: 'mm' },
      series: [{ name: 'Chuva em (mm)', data: [], color: '#00B6FF' }],
      xAxis: { categories: [] },
    };

    for (let i = 0; i < dados.length; i += 1) {
      const dado = dados[i];

      optionsGraficoTemperatura.xAxis.categories.push(dado.HR_MEDICAO.slice(0, 2));
      optionsGraficoTemperatura.series[0].data.push(parseFloat(dado.TEM_MAX));
      optionsGraficoTemperatura.series[1].data.push(parseFloat(dado.TEM_INS));
      optionsGraficoTemperatura.series[2].data.push(parseFloat(dado.TEM_MIN));

      optionsGraficoUmidade.xAxis.categories.push(dado.HR_MEDICAO.slice(0, 2));
      optionsGraficoUmidade.series[0].data.push(parseFloat(dado.UMD_MAX));
      optionsGraficoUmidade.series[1].data.push(parseFloat(dado.UMD_INS));
      optionsGraficoUmidade.series[2].data.push(parseFloat(dado.UMD_MIN));

      optionsGraficoOrvalho.xAxis.categories.push(dado.HR_MEDICAO.slice(0, 2));
      optionsGraficoOrvalho.series[0].data.push(parseFloat(dado.PTO_MAX));
      optionsGraficoOrvalho.series[1].data.push(parseFloat(dado.PTO_INS));
      optionsGraficoOrvalho.series[2].data.push(parseFloat(dado.PTO_MIN));

      optionsGraficoPressao.xAxis.categories.push(dado.HR_MEDICAO.slice(0, 2));
      optionsGraficoPressao.series[0].data.push(parseFloat(dado.PRE_MAX));
      optionsGraficoPressao.series[1].data.push(parseFloat(dado.PRE_INS));
      optionsGraficoPressao.series[2].data.push(parseFloat(dado.PRE_MIN));

      optionsGraficoChuva.xAxis.categories.push(dado.HR_MEDICAO.slice(0, 2));
      optionsGraficoChuva.series[0].data.push(parseFloat(dado.CHUVA));
    }

    setTabs([{
      menuItem: 'Temperatura',
      pane: {
        key: Math.random(),
        content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoTemperatura} />,
      },
    }, {
      menuItem: 'Umidade',
      pane: {
        key: Math.random(),
        content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoUmidade} />,
      },
    }, {
      menuItem: 'Ponto de Orvalho',
      pane: {
        key: Math.random(),
        content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoOrvalho} />,
      },
    }, {
      menuItem: 'Pressão',
      pane: {
        key: Math.random(),
        content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoPressao} />,
      },
    }, {
      menuItem: 'Chuva',
      pane: {
        key: Math.random(),
        content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoChuva} />,
      },
    }]);
  };

  const getDados = async () => {
    if ('data' in submitPost && 'estacao' in submitPost) {
      const codigo = submitPost.estacao.split('::')[0];
      /* eslint-disable camelcase */
      const vro = btoa(randomstring.generate(50));
      const dec = atob(vro);
      // eslint-disable-next-line
      var decvro = window.btoa(randomstring.generate(50))
      // eslint-disable-next-line
      decvro = decvro.slice(0,-1)
      // eslint-disable-next-line
      const seed = dec + '&' + decvro + vro
      const data_inicio = submitPost.data;
      const data_fim = submitPost.data;
      const estacao = codigo;
      // eslint-disable-next-line
      window.grecaptcha.ready(function() {
      // eslint-disable-next-line
        window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(token) {
          // eslint-disable-next-line
          await ApiTempo.post('/estacao/front/', { data_inicio, data_fim, estacao, seed, gcap: token })
            .then((response) => criarTabs(response.data))
            .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
              // eslint-disable-next-line
            window.alert("Erro ao buscar dados das estações.");
            });
        });
      });
    }
  };

  useEffect(() => {
    getDados();
  }, [submitPost]);

  if (produto === 'GraficosDiarios' && submit && Object.keys(submitPost) !== 0) {
    if (tabs.length === 0) {
      return <Loading />;
    }

    return (
      <div>
        <span className="titulo-data">
          {/* eslint-disable-next-line */}
          *Dados disponíveis em tempo real (sem controle de qualidade).
        </span>
        <Tab
          menu={{ pointing: true, className: 'wrapped-tab' }}
          panes={tabs}
          renderActiveOnly={false}
        />
      </div>
    );
  }

  return <Loading />;
}
export default GraficosDiarios;
