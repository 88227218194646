import React, { useState, useEffect } from 'react';
import {
  Image, Divider, Button, Icon,
} from 'semantic-ui-react';
import { useProduto } from '../../../contexts/Produto';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import ApiTempo from '../../../services/ApiTempo';
import Loading from '../../Loading/Index';

import './Style.css';

function Anomalias() {
  const [imagem, setImagem] = useState(null);
  const [imagens, setImagens] = useState([]);
  const [fullscreen, setFullscreen] = useState(false);
  const [produto] = useProduto();
  const [submit] = useSubmit();
  const [submitPost] = useSubmitPost();

  const getImagens = async () => {
    await ApiTempo.get(`/anomalias/${submitPost.data}`)
      .then((response) => {
        setImagens(response.data);
        setImagem(response.data[0].base64);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao buscar imagem.");
      });
  };

  useEffect(() => {
    if ('data' in submitPost && submitPost.data) {
      getImagens();
    }
  }, [submitPost]);

  const handlerImagem = (base64) => {
    setImagem(base64);
  };

  const handlerFullscreen = () => {
    if (!fullscreen) {
      const element = document.getElementById('content-fullscreen');
      const tmp = (
        element.mozRequestFullScreen
        || element.webkitRequestFullscreen // Safari
        || element.requestFullscreen // IE11
      );
      tmp.call(element);
    } else {
      document.exitFullscreen();
    }

    setFullscreen(!fullscreen);
  };

  if (produto === 'Anomalias' && submit && Object.keys(submitPost) !== 0 && imagens.length !== 0 && imagem) {
    return (
      <div className="content" id="content-fullscreen">
        <Image src={imagem} size={fullscreen ? 'huge' : 'big'} rounded />

        <Button icon size="massive" onClick={handlerFullscreen} className="fullscreen-button">
          <Icon name={fullscreen ? 'compress' : 'expand'} />
        </Button>

        <Divider horizontal>Parâmetros</Divider>
        <Button.Group compact style={{ display: 'flex', flexWrap: 'wrap' }}>
          {imagens && imagens.length !== 0
            ? imagens.map((item) => (
              <Button
                key={item.id}
                onClick={() => handlerImagem(item.base64)}
                className={item.base64 === imagem ? 'btn-active' : ''}
              >
                {item.parametro}
              </Button>
            ))
            : null}
        </Button.Group>
      </div>
    );
  }

  return <Loading />;
}

export default Anomalias;
