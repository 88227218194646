import React, { useState, useEffect } from 'react';
import {
  Button, Dropdown, Input, Icon, Label,
} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import { useVisibleSidebar } from '../../../contexts/VisibleSidebar';
import ApiTempo from '../../../services/ApiTempo';

function TabelaEstacoes() {
  const params = useParams();

  const estados = [
    { key: null, value: null, text: 'Todos' },
    { key: 'AC', value: 'AC', text: 'Acre' },
    { key: 'AL', value: 'AL', text: 'Alagoas' },
    { key: 'AP', value: 'AP', text: 'Amapá' },
    { key: 'AM', value: 'AM', text: 'Amazonas' },
    { key: 'BA', value: 'BA', text: 'Bahia' },
    { key: 'CE', value: 'CE', text: 'Ceará' },
    { key: 'DF', value: 'DF', text: 'Distrito Federal' },
    { key: 'ES', value: 'ES', text: 'Espírito Santo' },
    { key: 'GO', value: 'GO', text: 'Goiás' },
    { key: 'MA', value: 'MA', text: 'Maranhão' },
    { key: 'MT', value: 'MT', text: 'Mato Grosso' },
    { key: 'MS', value: 'MS', text: 'Mato Grosso do Sul' },
    { key: 'MG', value: 'MG', text: 'Minas Gerais' },
    { key: 'PA', value: 'PA', text: 'Pará' },
    { key: 'PB', value: 'PB', text: 'Paraíba' },
    { key: 'PR', value: 'PR', text: 'Paraná' },
    { key: 'PE', value: 'PE', text: 'Pernambuco' },
    { key: 'PI', value: 'PI', text: 'Piauí' },
    { key: 'RJ', value: 'RJ', text: 'Rio de Janeiro' },
    { key: 'RN', value: 'RN', text: 'Rio Grande do Norte' },
    { key: 'RS', value: 'RS', text: 'Rio Grande do Sul' },
    { key: 'RO', value: 'RO', text: 'Rondônia' },
    { key: 'RR', value: 'RR', text: 'Roraima' },
    { key: 'SC', value: 'SC', text: 'Santa Catarina' },
    { key: 'SP', value: 'SP', text: 'São Paulo' },
    { key: 'SE', value: 'SE', text: 'Sergipe' },
    { key: 'TO', value: 'TO', text: 'Tocantins' },
  ];

  const maxDate = new Date().toISOString().split('T')[0];

  const [estacoes, setEstacoes] = useState([]);
  const [estacoesAutomaticas, setEstacoesAutomaticas] = useState([]);
  const [estacoesConvencionais, setEstacoesConvencionais] = useState([]);
  const [estadoSelecionado, setEstadoSelecionado] = useState(null);

  const [tipoEstacao, setTipoEstacao] = useState('T');
  const [estacao, setEstacao] = useState(null);
  const [dataInicio, setDataInicio] = useState(maxDate);
  const [dataFim, setDataFim] = useState(maxDate);

  const [errorEstacao, setErrorEstacao] = useState(null);
  const [errorDataInicio, setErrorDataInicio] = useState(null);
  const [errorDataFim, setErrorDataFim] = useState(null);

  const [, setSubmit] = useSubmit();
  const [submitPost, setSubmitPost] = useSubmitPost();
  const [, setVisibleSidebar] = useVisibleSidebar();

  const handlerEstacao = (event, selected) => {
    setSubmit(false);
    setErrorEstacao(false);
    setEstacao(selected.value);
  };

  const handlerEstado = (event, selected) => {
    setSubmit(false);
    setEstacao(null);
    setEstadoSelecionado(selected.value);
  };

  const handlerSubmit = () => {
    setSubmitPost({
      tipoEstacao, estacao, dataInicio, dataFim,
    });
  };

  const validateForm = () => {
    if (!estacao) {
      setErrorEstacao(true);
      return false;
    }

    if (!dataInicio) {
      setErrorDataInicio(true);
      return false;
    }

    if (!dataFim) {
      errorDataFim(true);
      return false;
    }

    setErrorEstacao(false);
    setErrorDataInicio(false);
    setErrorDataFim(false);
    return true;
  };

  useEffect(() => {
    setSubmit(false);
    setEstacao(null);
    setEstacoes(tipoEstacao === 'T' ? estacoesAutomaticas : estacoesConvencionais);
  }, [tipoEstacao]);

  const getEstacoes = async (tipo) => {
    await ApiTempo.get(`/estacoes/${tipo}`)
      .then((response) => {
        const tempEstacoes = response.data.map((item) => ({
          key: item.CD_ESTACAO,
          text: `${item.DC_NOME} (${item.CD_ESTACAO})`,
          value: `${item.CD_ESTACAO}::${item.DC_NOME}`,
          estado: item.SG_ESTADO,
        }));

        if (tipo === 'T') {
          setEstacoesAutomaticas(tempEstacoes);
          setEstacoes(tempEstacoes);
        }

        if (tipo === 'M') {
          setEstacoesConvencionais(tempEstacoes);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao carregar lista de estações.");
      });
  };

  useEffect(() => {
    getEstacoes('T');
    getEstacoes('M');
  }, []);

  useEffect(() => {
    const validate = validateForm();
    if (validate && Object.keys(submitPost).length !== 0) {
      setSubmit(true);
      setVisibleSidebar(false);
    }

    if (Object.keys(params).length !== 0
    && Object.keys(submitPost).length !== 0
    && 'estacao' in submitPost && submitPost.estacao
    && 'tipoEstacao' in submitPost && submitPost.tipoEstacao
    && 'dataInicio' in submitPost && submitPost.dataInicio
    && 'dataFim' in submitPost && submitPost.dataFim
    ) {
      setEstacao(submitPost.estacao);
      setTipoEstacao(submitPost.tipoEstacao);
      setDataInicio(submitPost.dataInicio);
      setDataFim(submitPost.dataFim);
      setSubmit(true);
      setVisibleSidebar(false);
    }
  }, [submitPost]);

  const handlerDataInicio = (event) => {
    setSubmit(false);
    setErrorDataInicio(false);
    setDataInicio(event.target.value);
  };

  const handlerDataFim = (event) => {
    setSubmit(false);
    setErrorDataFim(false);
    setDataFim(event.target.value);
  };

  return (
    <>
      <span className="sidebar-item-label">Tipo Estação</span>
      <Button.Group compact style={{ marginBottom: 15 }}>
        <Button
          key="T"
          onClick={() => setTipoEstacao('T')}
          className={tipoEstacao === 'T' ? 'btn-active' : ''}
        >
          Automáticas
        </Button>
        <Button
          key="M"
          onClick={() => setTipoEstacao('M')}
          className={tipoEstacao === 'M' ? 'btn-active' : ''}
        >
          Convencionais
        </Button>
      </Button.Group>

      <span className="sidebar-item-label">Estado</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={estados}
        onChange={handlerEstado}
        value={estadoSelecionado}
        loading={estados.length === 0}
        style={{ marginBottom: 15 }}
      />

      <span className="sidebar-item-label">Estação</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={estadoSelecionado
          ? estacoes.filter((item) => item.estado === estadoSelecionado)
          : estacoes}
        onChange={handlerEstacao}
        value={estacao}
        loading={estacao?.length === 0}
        style={{ marginBottom: 15 }}
        error={errorEstacao}
      />

      <span className="sidebar-item-label">Data Início</span>
      <Input iconPosition="left" error={errorDataInicio} style={{ marginBottom: 15 }}>
        <Icon name="calendar" />
        <input type="date" max={maxDate} value={dataInicio} onChange={handlerDataInicio} />
      </Input>

      <span className="sidebar-item-label">Data Fim</span>
      <Input iconPosition="left" error={errorDataFim} style={{ marginBottom: 15 }}>
        <Icon name="calendar" />
        <input type="date" max={maxDate} value={dataFim} onChange={handlerDataFim} />
      </Input>

      <Button onClick={handlerSubmit}>Gerar Tabela</Button>
      <Label basic color="red" pointing prompt style={{ marginTop: '10px', fontSize: '14px' }}>
        Atenção! O intervalo de datas não deve exceder 6 meses!
      </Label>
    </>
  );
}

export default TabelaEstacoes;
