import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import { useVisibleSidebar } from '../../../contexts/VisibleSidebar';
import ApiTempo from '../../../services/ApiTempo';

function Anomalias() {
  const params = useParams();

  const [datas, setDatas] = useState([]);
  const [dataSelecionada, setDataSelecionada] = useState(null);
  const [errorData, setErrorData] = useState(false);

  const [, setSubmit] = useSubmit();
  const [submitPost, setSubmitPost] = useSubmitPost();
  const [, setVisibleSidebar] = useVisibleSidebar();

  const validateForm = () => {
    if (!dataSelecionada) {
      setErrorData(true);
      return false;
    }

    setErrorData(false);
    return true;
  };

  const handlerSubmit = () => {
    setSubmitPost({ data: dataSelecionada });
  };

  const getDatas = async () => {
    await ApiTempo.get('/anomalias/datas/disponiveis')
      .then((response) => {
        setDatas(() => response.data.map((item) => ({
          key: item.data.split('T')[0],
          text: item.data.split('T')[0].split('-').reverse().join('/'),
          value: item.data.split('T')[0],
        })));
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao carregar lista de parâmetros.");
      });
  };

  useEffect(() => {
    getDatas();
  }, []);

  useEffect(() => {
    const validate = validateForm();
    if (validate) {
      setSubmit(true);
      setVisibleSidebar(false);
    }

    if (Object.keys(params).length !== 0 && Object.keys(submitPost).length !== 0 && 'data' in submitPost && submitPost.data) {
      setDataSelecionada(submitPost.data);
      setSubmit(true);
      setVisibleSidebar(false);
    }
  }, [submitPost]);

  const handlerData = (event, selected) => {
    setErrorData(false);
    setDataSelecionada(selected.value);
  };

  return (
    <>
      <span className="sidebar-item-label">Data</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={datas}
        onChange={handlerData}
        value={dataSelecionada}
        loading={datas.length === 0}
        style={{ marginBottom: 15 }}
        error={errorData}
      />

      <Button onClick={handlerSubmit}>Gerar Imagem</Button>
    </>
  );
}

export default Anomalias;
