import React, { useState, useEffect } from 'react';
import { Tab } from 'semantic-ui-react';
import atob from 'atob';
import btoa from 'btoa';
import randomstring from 'randomstring';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useProduto } from '../../../contexts/Produto';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import ApiTempo from '../../../services/ApiTempo';
import Loading from '../../Loading/Index';

import './Style.css';

function Graficos() {
  const [tabs, setTabs] = useState([]);
  const [produto] = useProduto();
  const [submit] = useSubmit();
  const [submitPost] = useSubmitPost();

  const getUltimoDiaMes = (ano, mes) => new Date((new Date(ano, mes, 1)) - 1).getDate();

  const getDadosNormal = async (x, y, estacao) => {
    /*
      X => https://apitempo.inmet.gov.br/normais/
        - 1 : NORMAIS CLIMATOLÓGICAS 1961 - 1990 - (ED. 2009)
        - 2 : NORMAIS CLIMATOLÓGICAS 1931 - 1960 - (ED. 1964)
        - 5 : NORMAIS CLIMATOLÓGICAS 1981 - 2010 - (ED. 2018)
        - 8 : NORMAIS CLIMATOLÓGICAS 1991 - 2020 - (ED. 2022)

      Y => https://apitempo.inmet.gov.br/normais/atributos/${x}/${estacao}
        - N201 : TEMPERATURA MÁXIMA, DIARIA - NORMAL
    */

    const dados = await ApiTempo.get(`/normais/consulta/${x}/${y}/${estacao}`)
      .then((response) => response.data)
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao buscar dados da normal.");
      });

    return dados;
  };

  const getDadosHorariosConvencional = async (token) => {
    const codigo = submitPost.estacao.split('::')[0];
    const dataInicial = `${submitPost.ano}-${submitPost.mes}-01`;
    const dataFinal = `${submitPost.ano}-${submitPost.mes}-${getUltimoDiaMes(submitPost.ano, submitPost.mes)}`;
    /* eslint-disable camelcase */
    const vro = btoa(randomstring.generate(50));
    const dec = atob(vro);
    // eslint-disable-next-line
    var decvro = window.btoa(randomstring.generate(50))
    // eslint-disable-next-line
    decvro = decvro.slice(0,-1)
    // eslint-disable-next-line
    const seed = dec + '&' + decvro + vro
    const data_inicio = dataInicial;
    const data_fim = dataFinal;
    const estacao = codigo;
    // eslint-disable-next-line
    const dados = await ApiTempo.post('/estacao/front/', { data_inicio, data_fim, estacao, seed, gcap: token })
      .then((response) => response.data)
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao buscar dados da chuva mensal.");
      });
    return dados;
  };

  const getDadosChuvaAno = async (token) => {
    const codigo = submitPost.estacao.split('::')[0];
    const dataInicial = `${submitPost.ano}-01-01`;
    const dataFinal = `${submitPost.ano}-12-31`;
    const dados = [...new Array(12).fill(0)];
    /* eslint-disable camelcase */
    const vro = btoa(randomstring.generate(50));
    const dec = atob(vro);
    // eslint-disable-next-line
    var decvro = window.btoa(randomstring.generate(50))
    // eslint-disable-next-line
    decvro = decvro.slice(0,-1)
    // eslint-disable-next-line
    const seed = dec + '&' + decvro + vro
    const data_inicio = dataInicial;
    const data_fim = dataFinal;
    const estacao = codigo;
    // eslint-disable-next-line
    const temp = await ApiTempo.post('/estacao/diaria/front/', { data_inicio, data_fim, estacao, seed, gcap: token })
      .then((response) => response.data)
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao buscar dados da chuva mensal.");
      });
    for (let i = 0; i < temp.length; i += 1) {
      const dado = temp[i];
      if (dado.DT_MEDICAO && dado.CHUVA) {
        const index = parseInt(dado.DT_MEDICAO.split('-')[1], 10) - 1;
        dados[index] += parseFloat(dado.CHUVA);
      }
    }
    return dados.map((item) => parseFloat(item.toFixed(1)));
  };

  const criarTabsConvecionais = async (dados) => {
    // eslint-disable-next-line
    window.grecaptcha.ready(function() {
    // eslint-disable-next-line
      window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(token) {
        const [codigo, nome] = submitPost.estacao.split('::');

        const optionsGraficoTemperatura = {
          chart: { type: 'line' },
          title: { text: 'Instituto Nacional de Meteorologia - INMET' },
          subtitle: { text: `Temperaturas Diárias (Máxima, Média, Mínima) || Estação: ${nome} (${codigo}) - ${submitPost.mes}/${submitPost.ano}` },
          yAxis: { title: { text: 'Temperatura °C' } },
          tooltip: { valueSuffix: '°C' },
          xAxis: {
            categories: [],
            labels: { step: 1 },
            title: { text: 'Dias do Mês' },
          },
          plotOptions: { series: { pointStart: 1 } },
          series: [
            {
              name: 'Temp. Máxima', data: [], color: '#FF0000', type: 'spline',
            },
            {
              name: 'Temp. Média Comp.', data: [], color: '#00FF00', type: 'spline',
            },
            {
              name: 'Temp. Mínima', data: [], color: '#00B6FF', type: 'spline',
            },
          ],
        };

        const optionsGraficoBulbo = {
          chart: { type: 'line' },
          title: { text: 'Instituto Nacional de Meteorologia - INMET' },
          subtitle: { text: `Temperaturas do Ar (Bulbo Seco) || Estação: ${nome} (${codigo}) - ${submitPost.mes}/${submitPost.ano}` },
          yAxis: { title: { text: 'Temperatura °C' } },
          tooltip: { valueSuffix: '°C' },
          xAxis: {
            categories: [],
            labels: { step: 1 },
            title: { text: 'Dias do Mês' },
          },
          plotOptions: { series: { pointStart: 1 } },
          series: [
            {
              name: 'Temp. do Ar 00 UTC', data: [], color: '#FF0000', type: 'spline',
            },
            {
              name: 'Temp. do Ar 12 UTC', data: [], color: '#00FF00', type: 'spline',
            },
            {
              name: 'Temp. do Ar 18 UTC', data: [], color: '#00B6FF', type: 'spline',
            },
          ],
        };

        const optionsGraficoUmidade = {
          chart: { type: 'line' },
          title: { text: 'Instituto Nacional de Meteorologia - INMET' },
          subtitle: { text: `Umidade Relativa do Ar || Estação: ${nome} (${codigo}) - ${submitPost.mes}/${submitPost.ano}` },
          yAxis: { title: { text: 'Umidade %' } },
          tooltip: { valueSuffix: '%' },
          xAxis: {
            categories: [],
            labels: { step: 1 },
            title: { text: 'Dias do Mês' },
          },
          plotOptions: { series: { pointStart: 1 } },
          series: [
            {
              name: 'Umidade Relativa do Ar 00 UTC', data: [], color: '#FF0000', type: 'spline',
            },
            {
              name: 'Umidade Relativa do Ar 12 UTC', data: [], color: '#00FF00', type: 'spline',
            },
            {
              name: 'Umidade Relativa do Ar 18 UTC', data: [], color: '#00B6FF', type: 'spline',
            },
          ],
        };

        const optionsGraficoInsolacao = {
          chart: { type: 'column' },
          title: { text: 'Instituto Nacional de Meteorologia - INMET' },
          subtitle: { text: `Insolação Total Diária || Estação: ${nome} (${codigo}) - ${submitPost.mes}/${submitPost.ano}` },
          yAxis: { title: { text: 'Insolação Total' } },
          tooltip: { valueSuffix: '' },
          xAxis: {
            categories: [],
            labels: { step: 1 },
            title: { text: 'Dias do Mês' },
          },
          plotOptions: { series: { pointStart: 1 } },
          series: [{
            name: 'Insolação Total', data: [], color: '#00B6FF', type: 'column',
          }],
        };

        const optionsGraficoTempMaxNormal = {
          chart: { type: 'column' },
          title: { text: 'Instituto Nacional de Meteorologia - INMET' },
          subtitle: { text: `Temperatura Máxima Diária X Temperatura Máxima (Normal Climatológica 61-90 e 91-2020) || Estação: ${nome} (${codigo}) - ${submitPost.mes}/${submitPost.ano}` },
          xAxis: {
            categories: [],
            labels: { step: 1 },
            title: { text: 'Dias do Mês' },
          },
          plotOptions: { series: { pointStart: 1 } },
          series: [{
            name: 'Temperatura Máxima Diária',
            type: 'column',
            yAxis: 0,
            data: [],
            color: '#FF0000',
            tooltip: { valueSuffix: ' °C' },

          }, {
            name: 'Temperatura Máxima Diária (Normal Climatológica 61-90)',
            type: 'spline',
            yAxis: 0,
            data: [],
            color: '#00FF00',
            tooltip: { valueSuffix: ' °C' },
          }, {
            name: 'Temperatura Máxima Diária (Normal Climatológica 91-2020)',
            type: 'spline',
            yAxis: 0,
            data: [],
            color: '#00B6FF',
            tooltip: { valueSuffix: ' °C' },
          }],
        };

        const optionsGraficoTempMinNormal = {
          chart: { type: 'column' },
          title: { text: 'Instituto Nacional de Meteorologia - INMET' },
          subtitle: { text: `Temperatura Mínima Diária X Temperatura Mínima (Normal Climatológica 61-90 e 91-2020) || Estação: ${nome} (${codigo}) - ${submitPost.mes}/${submitPost.ano}` },
          xAxis: {
            categories: [],
            labels: { step: 1 },
            title: { text: 'Dias do Mês' },
          },
          plotOptions: { series: { pointStart: 1 } },
          series: [{
            name: 'Temperatura Mínima Diária',
            type: 'column',
            yAxis: 0,
            data: [],
            color: '#FF0000',
            tooltip: { valueSuffix: ' °C' },

          }, {
            name: 'Temperatura Mínima Diária (Normal Climatológica 61-90)',
            type: 'spline',
            yAxis: 0,
            data: [],
            color: '#00FF00',
            tooltip: { valueSuffix: ' °C' },
          }, {
            name: 'Temperatura Mínima Diária (Normal Climatológica 91-2020)',
            type: 'spline',
            yAxis: 0,
            data: [],
            color: '#00B6FF',
            tooltip: { valueSuffix: ' °C' },
          }],
        };

        const optionsGraficoChuva = {
          chart: { type: 'column' },
          title: { text: 'Instituto Nacional de Meteorologia - INMET' },
          subtitle: { text: `Chuva Acumulada em 24h || Estação: ${nome} (${codigo}) - ${submitPost.mes}/${submitPost.ano}` },
          yAxis: { title: { text: 'Chuva em (mm)' } },
          tooltip: { valueSuffix: '' },
          xAxis: {
            categories: [],
            labels: { step: 1 },
            title: { text: 'Dias do Mês' },
          },
          plotOptions: { series: { pointStart: 1 } },
          series: [{
            name: 'Chuva em (mm)', data: [], color: '#00B6FF', type: 'column',
          }],
        };

        const optionsGraficoChuvaNormal = {
          chart: { type: 'column' },
          title: { text: 'Instituto Nacional de Meteorologia - INMET' },
          subtitle: { text: `Chuva Acumulada Mensal X Chuva (Normal Climatológica 61-90 e 91-2020) || Estação: ${nome} (${codigo}) - ${submitPost.ano}` },
          xAxis: {
            categories: [
              `Janeiro/${submitPost.ano}`,
              `Fevereiro/${submitPost.ano}`,
              `Março/${submitPost.ano}`,
              `Abril/${submitPost.ano}`,
              `Maio/${submitPost.ano}`,
              `Junho/${submitPost.ano}`,
              `Julho/${submitPost.ano}`,
              `Agosto/${submitPost.ano}`,
              `Setembro/${submitPost.ano}`,
              `Outubro/${submitPost.ano}`,
              `Novembro/${submitPost.ano}`,
              `Dezembro/${submitPost.ano}`,
            ],
            title: { text: 'Meses' },
          },
          series: [{
            name: 'Chuva Acumulada Mensal',
            type: 'column',
            yAxis: 0,
            data: [],
            color: '#95CEFF',
            tooltip: { valueSuffix: ' mm' },

          }, {
            name: 'Chuva Acumulada Mensal (Normal Climatológica 61-90)',
            type: 'spline',
            yAxis: 0,
            data: [],
            color: 'black',
            tooltip: { valueSuffix: ' mm' },
          }, {
            name: 'Chuva Acumulada Mensal (Normal Climatológica 91-2020)',
            type: 'spline',
            yAxis: 0,
            data: [],
            color: 'green',
            tooltip: { valueSuffix: ' mm' },
          }],
        };

        for (let i = 0; i < dados.length; i += 1) {
          const dado = dados[i];

          optionsGraficoTemperatura.series[0].data.push(parseFloat(dado.TEMP_MAX));
          optionsGraficoTemperatura.series[1].data.push(parseFloat(dado.TEMP_MED));
          optionsGraficoTemperatura.series[2].data.push(parseFloat(dado.TEMP_MIN));

          optionsGraficoInsolacao.series[0].data.push(parseFloat(dado.INSOLACAO));

          optionsGraficoTempMaxNormal.series[0].data.push(parseFloat(dado.TEMP_MAX));

          optionsGraficoTempMinNormal.series[0].data.push(parseFloat(dado.TEMP_MIN));
        }
        const dadosHorarios = await getDadosHorariosConvencional(token);
        for (let i = 0; i < dadosHorarios.length; i += 1) {
          const dado = dadosHorarios[i];

          if (dado.HR_MEDICAO === '0000') {
            optionsGraficoBulbo.series[0].data.push(parseFloat(dado.TEMP_HORA));
            optionsGraficoUmidade.series[0].data.push(parseFloat(dado.UMID_HORA));
          }
          if (dado.HR_MEDICAO === '1200') {
            optionsGraficoBulbo.series[1].data.push(parseFloat(dado.TEMP_HORA));
            optionsGraficoUmidade.series[1].data.push(parseFloat(dado.UMID_HORA));
            optionsGraficoChuva.series[0].data.push(parseFloat(dado.CHUVA));
          }
          if (dado.HR_MEDICAO === '1800') {
            optionsGraficoBulbo.series[2].data.push(parseFloat(dado.TEMP_HORA));
            optionsGraficoUmidade.series[2].data.push(parseFloat(dado.UMID_HORA));
          }
        }
        // eslint-disable-next-line
        window.grecaptcha.ready(function() {
        // eslint-disable-next-line
          window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(tk) {
            optionsGraficoChuvaNormal.series[0].data = await getDadosChuvaAno(tk);

            const mesBuscado = parseInt(submitPost.mes, 10);
            let normal = await getDadosNormal(1, 'N201', codigo);
            for (let i = 0; i < normal.length; i += 1) {
              const dado = normal[i];
              const mes = parseInt(dado.MES_MEDICAO, 10);
              if (mesBuscado === mes) {
                optionsGraficoTempMaxNormal.series[1].data.push(
                  // eslint-disable-next-line
                  ...new Array(getUltimoDiaMes(submitPost.ano, mesBuscado)).fill(parseInt(dado.VALOR, 10)),
                );
                break;
              }
            }

            normal = await getDadosNormal(8, 'N201', codigo);
            for (let i = 0; i < normal.length; i += 1) {
              const dado = normal[i];
              const mes = parseInt(dado.MES_MEDICAO, 10);
              if (mesBuscado === mes) {
                optionsGraficoTempMaxNormal.series[2].data.push(
                  // eslint-disable-next-line
                  ...new Array(getUltimoDiaMes(submitPost.ano, mesBuscado)).fill(parseInt(dado.VALOR, 10)),
                );
                break;
              }
            }

            normal = await getDadosNormal(1, 'N202', codigo);
            for (let i = 0; i < normal.length; i += 1) {
              const dado = normal[i];
              const mes = parseInt(dado.MES_MEDICAO, 10);
              if (mesBuscado === mes) {
                optionsGraficoTempMinNormal.series[1].data.push(
                  // eslint-disable-next-line
                  ...new Array(getUltimoDiaMes(submitPost.ano, mesBuscado)).fill(parseFloat(dado.VALOR)),
                );
                break;
              }
            }

            normal = await getDadosNormal(8, 'N202', codigo);
            for (let i = 0; i < normal.length; i += 1) {
              const dado = normal[i];
              const mes = parseInt(dado.MES_MEDICAO, 10);
              if (mesBuscado === mes) {
                optionsGraficoTempMinNormal.series[2].data.push(
                  // eslint-disable-next-line
                  ...new Array(getUltimoDiaMes(submitPost.ano, mesBuscado)).fill(parseFloat(dado.VALOR)),
                );
                break;
              }
            }

            normal = await getDadosNormal(1, 'N208', codigo);
            // Menos 1 pq não quer adicionar o valor final que é a soma do ano
            for (let i = 0; i < normal.length - 1; i += 1) {
              const dado = normal[i];
              if (dado.VALOR) {
                optionsGraficoChuvaNormal.series[1].data.push(parseFloat(dado.VALOR));
              }
            }

            normal = await getDadosNormal(8, 'N208', codigo);
            // Menos 1 pq não quer adicionar o valor final que é a soma do ano
            for (let i = 0; i < normal.length - 1; i += 1) {
              const dado = normal[i];
              if (dado.VALOR) {
                optionsGraficoChuvaNormal.series[2].data.push(parseFloat(dado.VALOR));
              }
            }

            setTabs([{
              menuItem: 'Temperatura Diárias (Máxima, Média, Mínima)',
              pane: {
                key: Math.random(),
                // eslint-disable-next-line
                content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoTemperatura} />,
              },
            }, {
              menuItem: 'Temperatura do Bulbo Seco (00 - 12 - 18 UTC)',
              pane: {
                key: Math.random(),
                content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoBulbo} />,
              },
            }, {
              menuItem: 'Umidade (00 - 12 - 18 UTC)',
              pane: {
                key: Math.random(),
                // eslint-disable-next-line
                content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoUmidade} />,
              },
            }, {
              menuItem: 'Insolação Total Diária',
              pane: {
                key: Math.random(),
                // eslint-disable-next-line
                content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoInsolacao} />,
              },
            }, {
              menuItem: 'Temperatura Máxima Diária X Temperatura Máxima (Normal Climatológica 61-90 e 91-2020)',
              pane: {
                key: Math.random(),
                // eslint-disable-next-line
                content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoTempMaxNormal} />,
              },
            }, {
              menuItem: 'Temperatura Mínima Diária X Temperatura Mínima (Normal Climatológica 61-90 e 91-2020)',
              pane: {
                key: Math.random(),
                // eslint-disable-next-line
                content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoTempMinNormal} />,
              },
            }, {
              menuItem: 'Chuva Acumulada em 24h',
              pane: {
                key: Math.random(),
                content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoChuva} />,
              },
            }, {
              menuItem: 'Chuva Acumulada Mensal X Chuva (Normal Climatológica 61-90 e 91-2020)',
              pane: {
                key: Math.random(),
                // eslint-disable-next-line
                content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoChuvaNormal} />,
              },
            }]);
          });
        });
      });
    });
  };

  const criarTabsAutomaticas = async (dados) => {
    // eslint-disable-next-line
    window.grecaptcha.ready(function() {
    // eslint-disable-next-line
      window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(token) {
        const [codigo, nome] = submitPost.estacao.split('::');

        const optionsGraficoTemperatura = {
          chart: { type: 'line' },
          title: { text: 'Instituto Nacional de Meteorologia - INMET' },
          subtitle: { text: `Temperaturas Diárias (Máxima, Média, Mínima) || Estação: ${nome} (${codigo}) - ${submitPost.mes}/${submitPost.ano}` },
          yAxis: { title: { text: 'Temperatura °C' } },
          tooltip: { valueSuffix: '°C' },
          xAxis: {
            categories: [],
            labels: { step: 1 },
            title: { text: 'Dias do Mês' },
          },
          plotOptions: { series: { pointStart: 1 } },
          series: [
            { name: 'Temp. Máxima', data: [], color: '#FF0000' },
            { name: 'Temp. Média Comp.', data: [], color: '#00FF00' },
            { name: 'Temp. Mínima', data: [], color: '#00B6FF' },
          ],
        };

        const optionsGraficoUmidade = {
          chart: { type: 'line' },
          title: { text: 'Instituto Nacional de Meteorologia - INMET' },
          subtitle: { text: `Umidades Diárias (Média, Mínima) || Estação: ${nome} (${codigo}) - ${submitPost.mes}/${submitPost.ano}` },
          yAxis: { title: { text: 'Umidade %' } },
          tooltip: { valueSuffix: '%' },
          xAxis: {
            categories: [],
            labels: { step: 1 },
            title: { text: 'Dias do Mês' },
          },
          plotOptions: { series: { pointStart: 1 } },
          series: [
            { name: 'Umidade Média', data: [], color: '#00FF00' },
            { name: 'Umidade Mínima', data: [], color: '#00B6FF' },
          ],
        };

        const optionsGraficoChuva = {
          chart: { type: 'column' },
          title: { text: 'Instituto Nacional de Meteorologia - INMET' },
          subtitle: { text: `Chuva Acumulada em 24h || Estação: ${nome} (${codigo}) - ${submitPost.mes}/${submitPost.ano}` },
          yAxis: { title: { text: 'Chuva em mm' } },
          tooltip: { valueSuffix: 'mm' },
          xAxis: {
            categories: [],
            labels: { step: 1 },
            title: { text: 'Dias do Mês' },
          },
          plotOptions: { series: { pointStart: 1 } },
          series: [{ name: 'Chuva em (mm)', data: [], color: '#00B6FF' }],
        };

        const optionsGraficoChuvaMensal = {
          chart: { type: 'column' },
          title: { text: 'Instituto Nacional de Meteorologia - INMET' },
          subtitle: { text: `Chuva Acumulada Mensal || Estação: ${nome} (${codigo}) - ${submitPost.mes}/${submitPost.ano}` },
          yAxis: { title: { text: 'Chuva em mm' } },
          tooltip: { valueSuffix: 'mm' },
          series: [{ name: 'Chuva em (mm)', data: [], color: '#00B6FF' }],
          xAxis: {
            categories: [
              `Janeiro/${submitPost.ano}`,
              `Fevereiro/${submitPost.ano}`,
              `Março/${submitPost.ano}`,
              `Abril/${submitPost.ano}`,
              `Maio/${submitPost.ano}`,
              `Junho/${submitPost.ano}`,
              `Julho/${submitPost.ano}`,
              `Agosto/${submitPost.ano}`,
              `Setembro/${submitPost.ano}`,
              `Outubro/${submitPost.ano}`,
              `Novembro/${submitPost.ano}`,
              `Dezembro/${submitPost.ano}`,
            ],
            title: { text: 'Meses' },
          },
        };

        for (let i = 0; i < dados.length; i += 1) {
          const dado = dados[i];

          optionsGraficoTemperatura.series[0].data.push(parseFloat(dado.TEMP_MAX));
          optionsGraficoTemperatura.series[1].data.push(parseFloat(dado.TEMP_MED));
          optionsGraficoTemperatura.series[2].data.push(parseFloat(dado.TEMP_MIN));

          optionsGraficoUmidade.series[0].data.push(parseFloat(dado.UMID_MED));
          optionsGraficoUmidade.series[1].data.push(parseFloat(dado.UMID_MIN));

          optionsGraficoChuva.series[0].data.push(parseFloat(dado.CHUVA));
        }
        optionsGraficoChuvaMensal.series[0].data = await getDadosChuvaAno(token);

        setTabs([{
          menuItem: 'Temperatura Diárias (Máxima, Média, Mínima)',
          pane: {
            key: Math.random(),
            // eslint-disable-next-line
            content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoTemperatura} />,
          },
        }, {
          menuItem: 'Umidades Diárias (Média, Mínima)',
          pane: {
            key: Math.random(),
            content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoUmidade} />,
          },
        }, {
          menuItem: 'Chuva Acumulada em 24h',
          pane: {
            key: Math.random(),
            content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoChuva} />,
          },
        }, {
          menuItem: 'Chuva Acumulada Mensal',
          pane: {
            key: Math.random(),
            // eslint-disable-next-line
            content: <HighchartsReact highcharts={Highcharts} options={optionsGraficoChuvaMensal} />,
          },
        }]);
      });
    });
  };

  const criarTabs = (dados) => {
    if (submitPost.tipoEstacao === 'T') {
      criarTabsAutomaticas(dados);
    }

    if (submitPost.tipoEstacao === 'M') {
      criarTabsConvecionais(dados);
    }
  };

  const getDados = async (token) => {
    if ('tipoEstacao' in submitPost && 'estacao' in submitPost && 'mes' in submitPost && 'ano' in submitPost) {
      const codigo = submitPost.estacao.split('::')[0];
      const dataInicial = `${submitPost.ano}-${submitPost.mes}-01`;
      const dataFinal = `${submitPost.ano}-${submitPost.mes}-${getUltimoDiaMes(submitPost.ano, submitPost.mes)}`;
      /* eslint-disable camelcase */
      const vro = btoa(randomstring.generate(50));
      const dec = atob(vro);
      // eslint-disable-next-line
      var decvro = window.btoa(randomstring.generate(50))
      // eslint-disable-next-line
      decvro = decvro.slice(0,-1)
      // eslint-disable-next-line
      const seed = dec + '&' + decvro + vro
      const data_inicio = dataInicial;
      const data_fim = dataFinal;
      const estacao = codigo;
      // eslint-disable-next-line
      await ApiTempo.post('/estacao/diaria/front/', { data_inicio, data_fim, estacao, seed, gcap: token })
        .then((response) => criarTabs(response.data, submitPost.tipoEstacao))
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          // eslint-disable-next-line
          window.alert("Erro ao buscar dados das estações.");
        });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
    window.grecaptcha.ready(function() {
    // eslint-disable-next-line
      window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(token) {
        getDados(token);
      });
    });
  }, [submitPost]);

  if (produto === 'Graficos' && submit && Object.keys(submitPost) !== 0 && tabs.length !== 0) {
    return (
      <div>
        <span className="titulo-data">
          {/* eslint-disable-next-line */}
          *Dados disponíveis em tempo real (sem controle de qualidade).
        </span>
        <Tab
          menu={{ pointing: true, className: 'wrapped-tab' }}
          panes={tabs}
          renderActiveOnly={false}
        />
      </div>
    );
  }

  return <Loading />;
}
export default Graficos;
