import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'semantic-ui-react';
import atob from 'atob';
import btoa from 'btoa';
import randomstring from 'randomstring';
import { useProduto } from '../../../contexts/Produto';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import ApiTempo from '../../../services/ApiTempo';
import Loading from '../../Loading/Index';
import './Style.css';

function WSI() {
  const [tabela, setTabela] = useState([]);
  const [dados, setDados] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [produto] = useProduto();
  const [submit] = useSubmit();
  const [submitPost] = useSubmitPost();

  const formatarDadosAutomatica = () => {
    const tempTable = (
      <Table color="blue" unstackable>
        <Table.Header>
          <Table.Row key={Math.random()}>
            <Table.HeaderCell textAlign="center">Date</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Hour</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan={3}>Temperature (°C)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan={3}>Humidity (%)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan={3}>Dew Point (°C)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan={3}>Pressure (hPa)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan={3}>Wind</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Radiation</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Rain</Table.HeaderCell>
          </Table.Row>
          <Table.Row key={Math.random()}>
            <Table.HeaderCell textAlign="center">{' '}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">UTC</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Inst.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Máx.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Mín.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Inst.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Máx.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Mín.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Inst.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Máx.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Mín.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Inst.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Máx.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Mín.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Vel. (m/s)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Dir. (°)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Raj. (m/s)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Kj/m²</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">mm</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {dados.map((item) => (
            <Table.Row key={Math.random()}>
              <Table.Cell textAlign="center">{item.DT_MEDICAO ? item.DT_MEDICAO.split('-').reverse().join('/') : ''}</Table.Cell>
              <Table.Cell textAlign="center">{item.HR_MEDICAO ? item.HR_MEDICAO : ''}</Table.Cell>

              <Table.Cell textAlign="right">{item.TEM_INS ? parseFloat(item.TEM_INS).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.TEM_MAX ? parseFloat(item.TEM_MAX).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.TEM_MIN ? parseFloat(item.TEM_MIN).toFixed(1) : ''}</Table.Cell>

              <Table.Cell textAlign="right">{item.UMD_INS ? parseFloat(item.UMD_INS).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.UMD_MAX ? parseFloat(item.UMD_MAX).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.UMD_MIN ? parseFloat(item.UMD_MIN).toFixed(1) : ''}</Table.Cell>

              <Table.Cell textAlign="right">{item.PTO_INS ? parseFloat(item.PTO_INS).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.PTO_MAX ? parseFloat(item.PTO_MAX).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.PTO_MIN ? parseFloat(item.PTO_MIN).toFixed(1) : ''}</Table.Cell>

              <Table.Cell textAlign="right">{item.PRE_INS ? parseFloat(item.PRE_INS).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.PRE_MAX ? parseFloat(item.PRE_MAX).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.PRE_MIN ? parseFloat(item.PRE_MIN).toFixed(1) : ''}</Table.Cell>

              <Table.Cell textAlign="right">{item.VEN_VEL ? parseFloat(item.VEN_VEL).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.VEN_DIR ? parseFloat(item.VEN_DIR).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.VEN_RAJ ? parseFloat(item.VEN_RAJ).toFixed(1) : ''}</Table.Cell>

              <Table.Cell textAlign="right">{item.RAD_GLO && parseFloat(item.RAD_GLO) > 0 ? parseFloat(item.RAD_GLO).toFixed(2) : ''}</Table.Cell>

              <Table.Cell textAlign="right">{item.CHUVA ? parseFloat(item.CHUVA).toFixed(1) : ''}</Table.Cell>

            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
    setTabela(tempTable);
  };

  const formatarDadosConvencional = () => {
    const tempTable = (
      <Table color="blue" unstackable>
        <Table.Header>
          <Table.Row key={Math.random()}>
            <Table.HeaderCell textAlign="center">Date</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Hour</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Temperature</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Humidity</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Pressure</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan={2}>Wind</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Nebulosity</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Insolation</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan={3}>Daily Data</Table.HeaderCell>
          </Table.Row>
          <Table.Row key={Math.random()}>
            <Table.HeaderCell textAlign="center">{' '}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">UTC</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">°C</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">%</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">hPa</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Vel. (m/s)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Dir. (°)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Décimos</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">h</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Temp. Máx. (°C)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Temp. Mín. (°C)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Rain (mm)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {dados.map((item) => (
            <Table.Row key={Math.random()}>
              <Table.Cell textAlign="center">{item.DT_MEDICAO ? item.DT_MEDICAO.split('-').reverse().join('/') : ''}</Table.Cell>
              <Table.Cell textAlign="center">{item.HR_MEDICAO ? item.HR_MEDICAO : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.TEMP_HORA ? parseFloat(item.TEMP_HORA).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.UMID_HORA ? parseFloat(item.UMID_HORA).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.PRESS_EST ? parseFloat(item.PRESS_EST).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.VENT_VEL ? parseFloat(item.VENT_VEL).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.VENT_DIR ? parseFloat(item.VENT_DIR).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.NEBU_HORA ? parseFloat(item.NEBU_HORA).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.INSO_HORA ? parseFloat(item.INSO_HORA).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.TEMP_MAX ? parseFloat(item.TEMP_MAX).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.TEMP_MIN ? parseFloat(item.TEMP_MIN).toFixed(1) : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.CHUVA ? parseFloat(item.CHUVA).toFixed(1) : ''}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
    setTabela(tempTable);
  };

  const getDados = async () => {
    if ('dataInicio' in submitPost && 'dataFim' in submitPost && 'estacao' in submitPost) {
      /* eslint-disable camelcase */
      const vro = btoa(randomstring.generate(50));
      const dec = atob(vro);
      // eslint-disable-next-line
      var decvro = window.btoa(randomstring.generate(50))
      // eslint-disable-next-line
      decvro = decvro.slice(0,-1)
      // eslint-disable-next-line
      const seed = dec + '&' + decvro + vro
      const data_inicio = submitPost.dataInicio;
      const data_fim = submitPost.dataFim;
      const estacao = submitPost.estacao.split('::')[0];
      // eslint-disable-next-line
      window.grecaptcha.ready(function() {
      // eslint-disable-next-line
        window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(token) {
          // eslint-disable-next-line
          await ApiTempo.post('/estacao/front/', { data_inicio, data_fim, estacao, seed, gcap: token })
            .then((response) => setDados(response.data))
            .catch((error) => {
              // eslint-disable-next-line
              console.log(error);
              // eslint-disable-next-line
              window.alert("Erro ao carregar lista de produtos.");
            });
        });
      });
    }
  };

  useEffect(() => {
    if (dados) {
      if (submitPost.tipoEstacao === 'T') {
        formatarDadosAutomatica();
      }

      if (submitPost.tipoEstacao === 'M') {
        formatarDadosConvencional();
      }
    }
  }, [dados]);

  useEffect(() => {
    getDados();
  }, [submitPost]);

  const handlerModal = () => setModalVisible(!modalVisible);

  if (produto === 'WSI' && submit && Object.keys(submitPost) !== 0 && tabela && tabela.length !== 0) {
    return (
      <div className="content">
        <div className="titulo-content">
          <span className="titulo-data">
            Station:
            {' '}
            {submitPost.estacao.split('::')[2]}
            {' '}
            (
            {submitPost.estacao.split('::')[1]}
            )
          </span>
          <Button onClick={handlerModal} content="Instructions" />

          <Modal
            basic
            onClose={() => setModalVisible(false)}
            onOpen={() => setModalVisible(true)}
            open={modalVisible}
            size="large"
          >

            <Modal.Content className="modal-content">
              <p className="modal-text">
                <ul>
                  <li>Esta página exibe as estações do INMET renomeadas com o código WIGOS da OMM, registradas ou não na base OSCAR/Surface da OMM (https://oscar.wmo.int/surface//index.html#/)</li>
                  <li>O código é formado da seguinte maneira: </li>
                  <li>0 = fixo</li>
                  <li>76 = País (Código ISO para Brasil)</li>
                  <li>0 = (organização dona da estação 0 = INMET, 1 = Força Aérea, etc</li>
                  <li>nnnnnnnnnnnnnnnn = código da estação</li>
                </ul>
                <hr />
                <ul>
                  <li>This page displays INMET stations renamed with the WMOS code from OMM, registered or not on the OMM OSCAR / Surface base (https://oscar.wmo.int/surface//index.html#/)</li>
                  <li>The code is formed as follows:</li>
                  <li>0 = fixed</li>
                  <li>76 = Country (ISO code for Brazil)</li>
                  <li>0 = (organization that owns the station 0 = INMET, 1 = Air Force, etc.</li>
                  <li>nnnnnnnnnnnnnnnn = station code</li>
                </ul>
              </p>
            </Modal.Content>
          </Modal>
        </div>
        {tabela}
      </div>
    );
  }
  return <Loading />;
}

export default WSI;
