import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'semantic-ui-react';
import atob from 'atob';
import btoa from 'btoa';
import randomstring from 'randomstring';
import { useProduto } from '../../../contexts/Produto';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import ApiTempo from '../../../services/ApiTempo';
import Loading from '../../Loading/Index';
import './Style.css';

function ValoresExtremos() {
  const [dados, setDados] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [produto] = useProduto();
  const [submit] = useSubmit();
  const [submitPost] = useSubmitPost();

  const formatarDados = (response) => {
    const ret = [];
    for (let i = 0; i < response.length; i += 1) {
      const dado = response[i];
      ret.push({
        omm: dado.codigo_omm,
        inmet: dado.codigo_inmet,
        estacao: dado.nome_estacao,
        uf: dado.uf,
        valor: dado.valor ? parseFloat(dado.valor).toFixed(1) : null,
      });
    }
    setDados(ret);
  };

  const getDados = async () => {
    if ('data' in submitPost && 'parametro' in submitPost && 'abrangencia' in submitPost) {
      /* eslint-disable camelcase */
      const vro = btoa(randomstring.generate(50));
      const dec = atob(vro);
      const seed = vro + dec;
      const atributo = submitPost.parametro?.split('_')[0];
      const date = submitPost.data;
      const limite = '800';
      const tipoFiltro = submitPost.abrangencia;
      const filtro = submitPost.regiao;
      try {
        // eslint-disable-next-line
        window.grecaptcha.ready(function() {
        // eslint-disable-next-line
          window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(token) {
            // eslint-disable-next-line
            await ApiTempo.post('/valores/extremos/', { atributo, date, limite, tipoFiltro, filtro, seed, gcap: token })
              .then((response) => formatarDados(response.data))
              .catch((error) => {
                // eslint-disable-next-line
                console.log(error);
                // eslint-disable-next-line
                window.alert("Erro ao carregar lista de valores.");
              });
          });
        });
      } catch {
        // eslint-disable-next-line
        console.log('Erro inesperado');
      }
    }
  };

  const handlerModal = () => setModalVisible(!modalVisible);

  useEffect(() => {
    getDados();
  }, [submitPost]);

  if (produto === 'ValoresExtremos' && submit && Object.keys(submitPost) !== 0 && dados && dados.length !== 0) {
    return (
      <div className="content">
        <div className="titulo-content">
          <span className="titulo-data">
            Data de Referência:
            {' '}
            {submitPost.data?.split('-').reverse().join('/')}
          </span>
          <Button onClick={handlerModal} content="Instrução" />

          <Modal
            basic
            onClose={() => setModalVisible(false)}
            onOpen={() => setModalVisible(true)}
            open={modalVisible}
            size="large"
          >

            <Modal.Content className="modal-content">
              <p className="modal-text">
                <span>
                  Para a busca dos Valores Extremos são utilizadas estações
                  meteorológicas convencionais e automáticas do INMET, e os
                  seguintes critérios são utilizados:
                </span>

                <ol>
                  <li>
                    Maiores Temperaturas: Temperatura Máxima observada/registrada
                    no dia. Considera dados de 00 UTC (21 horas no horário de
                    Brasília - durante o horário de verão, 22 horas);
                  </li>
                  <li>
                    Menores Temperaturas: Temperatura Mínima observada/registrada
                    no dia. Considera dados de 12 UTC (09 horas no horário de
                    Brasília - durante o horário de verão, 10 horas);
                  </li>
                  <li>
                    Menores Umidades Relativas do Ar: Para as convencionais:
                    Menor Umidade Relativa do Ar observada entre os três horários de
                    observação (00, 12 e 18 UTC). Para as estações automáticas:
                    Menor Umidade Relativa do Ar observada entre todos os horários
                    do dia. Ambas são disponíveis para o horário de 00 UTC;
                  </li>
                  <li>
                    Maiores Valores de Chuva em 24 horas: Maiores acumulados de
                    chuva entre 12 UTC do dia anterior e 12 UTC do dia em questão.
                    Dado observado/registrado às 12 UTC (9 horas no horário de
                    Brasília).
                  </li>
                </ol>
              </p>
            </Modal.Content>
          </Modal>

        </div>
        <Table color="blue" unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Código OMM</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Código INMET</Table.HeaderCell>
              <Table.HeaderCell textAlign="left">Estação</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">UF</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                Valor
                {' '}
                {submitPost.parametro?.split('_')[1]}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {dados.map((item, index) => (
              <Table.Row key={Math.random()}>
                <Table.Cell textAlign="center">{index}</Table.Cell>
                <Table.Cell textAlign="center">{item.omm}</Table.Cell>
                <Table.Cell textAlign="center">{item.inmet}</Table.Cell>
                <Table.Cell textAlign="left">{item.estacao}</Table.Cell>
                <Table.Cell textAlign="center">{item.uf}</Table.Cell>
                <Table.Cell textAlign="right">{item.valor}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
  return <Loading />;
}

export default ValoresExtremos;
