import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Index from './pages/Index';
import Graficos from './pages/Graficos/Index';
import Pluviometros from './pages/Pluviometros/Index';
import Tabela from './pages/Tabela/Index';

function RoutesFile() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/:produto" element={<Index />} />
        <Route path="/:produto/:parametro" element={<Index />} />
        <Route path="/graficos/mapa/:estacao/:data" element={<Graficos />} />
        <Route path="/graficosp/mapa/:estacao/:data" element={<Pluviometros />} />
        <Route path="/tabela/mapa/:estacao/:data" element={<Tabela />} />
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesFile;
