import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'semantic-ui-react';
import { useProduto } from '../../../contexts/Produto';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import ApiTempo from '../../../services/ApiTempo';
import './Style.css';

function CondicoesTempoRegistradas() {
  const [condicoes, setCondicoes] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [produto] = useProduto();
  const [submit] = useSubmit();
  const [submitPost] = useSubmitPost();

  const formatarDados = (dados) => {
    const ret = [];
    for (let i = 0; i < dados.length; i += 1) {
      const dado = dados[i];
      ret.push({
        capital: dado.CAPITAL,
        tmin: {
          value: dado.TMIN18.replace('*', '').length > 0 ? `${parseFloat(dado.TMIN18).toFixed(1)}°C` : '-',
          color: dado.TMIN18.includes('*') && dado.TMIN18.length > 1 ? 'rgba(255, 255, 0, 0.6)' : '',
        },
        tmax: {
          value: dado.TMAX18.replace('*', '').length > 0 ? `${parseFloat(dado.TMAX18).toFixed(1)}°C` : '-',
          color: dado.TMAX18.includes('*') && dado.TMAX18.length > 1 ? 'rgba(255, 255, 0, 0.6)' : '',
        },
        umi: {
          value: dado.UMIN18.replace('*', '').length > 0 ? `${parseFloat(dado.UMIN18).toFixed(1)}%` : '-',
          color: dado.UMIN18.includes('*') && dado.UMIN18.length > 1 ? 'rgba(255, 255, 0, 0.6)' : '',
        },
        chuva: {
          value: dado.PMAX12.replace('*', '').length > 0 ? parseFloat(dado.PMAX12).toFixed(1) : '-',
          color: dado.PMAX12.includes('*') && dado.PMAX12.length > 1 ? 'rgba(255, 255, 0, 0.6)' : '',
        },
      });
    }
    setCondicoes(ret);
  };

  const getCondicoes = async () => {
    if ('data' in submitPost) {
      await ApiTempo.get(`/condicao/capitais/${submitPost.data}`)
        .then((response) => formatarDados(response.data))
        .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
          // eslint-disable-next-line
        window.alert("Erro ao carregar lista de produtos.");
        });
    }
  };

  const handlerModal = () => setModalVisible(!modalVisible);

  useEffect(() => {
    getCondicoes();
  }, [submitPost]);

  if (produto === 'CondicoesTempoRegistradas' && submit && Object.keys(submitPost) !== 0) {
    return (
      <div className="content">
        <div className="titulo-content">
          <span className="titulo-data">
            Data de Referência:
            {' '}
            {submitPost.data?.split('-').reverse().join('/')}
          </span>
          <Button onClick={handlerModal} content="Instrução" />
          {/* eslint-disable-next-line */}
          <span className="titulo-data">
            {/* eslint-disable-next-line */}
            Todos os dados são referentes até às 15 h (horário de Brasília). 
            Especialmente a temperatura máxima pode sofrer alteração até o final do dia!
          </span>
          <Modal
            basic
            onClose={() => setModalVisible(false)}
            onOpen={() => setModalVisible(true)}
            open={modalVisible}
            size="small"
          >

            <Modal.Content className="modal-content">
              <span className="modal-box">&nbsp;</span>
              <p className="modal-text">
                Dados registrados pelas Estações Automáticas
              </p>
            </Modal.Content>
          </Modal>

        </div>
        <Table color="blue" unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
              <Table.HeaderCell textAlign="left">CAPITAL</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">TEMP. MIN</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">TEMP. MAX</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">UMID. RELATIVA</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">CHUVA (mm)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {condicoes.map((item, index) => (
              <Table.Row key={Math.random()}>
                <Table.Cell textAlign="center">{index}</Table.Cell>
                <Table.Cell textAlign="left">{item.capital}</Table.Cell>
                <Table.Cell textAlign="right" style={{ backgroundColor: item.tmin.color, color: 'blue' }}>{item.tmin.value}</Table.Cell>
                <Table.Cell textAlign="right" style={{ backgroundColor: item.tmax.color, color: 'red' }}>{item.tmax.value}</Table.Cell>
                <Table.Cell textAlign="right" style={{ backgroundColor: item.umi.color }}>{item.umi.value}</Table.Cell>
                <Table.Cell textAlign="right" style={{ backgroundColor: item.chuva.color }}>{item.chuva.value}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
  return null;
}

export default CondicoesTempoRegistradas;
