/* eslint-disable react/jsx-props-no-spreading */
import React, {
  createContext, useContext, useState, useMemo,
} from 'react';

const SubmitPost = createContext();

function useSubmitPost() {
  const context = useContext(SubmitPost);

  if (!context) {
    // eslint-disable-next-line no-alert
    window.alert('Ocorreu um erro ao submeter o formulário, tente atualizar a página.');
  }

  return context;
}

function SubmitPostProvider(props) {
  const [submitPostEnviado, setSubmitPostEnviado] = useState({});
  const value = useMemo(() => [submitPostEnviado, setSubmitPostEnviado], [submitPostEnviado]);
  return <SubmitPost.Provider value={value} {...props} />;
}

export { useSubmitPost, SubmitPostProvider };
