import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import MenuSidebar from '../Menu/Index';
import { useVisibleSidebar } from '../../contexts/VisibleSidebar';
import './Style.css';

function FixedHeader() {
  const [sidebarVisible, setSidebarVisible] = useVisibleSidebar();

  const handlerSidebar = () => setSidebarVisible(!sidebarVisible);

  return (
    <>
      <Menu attached="top" className="header-container">
        <Menu.Menu position="left">
          <Icon name="bars" className="header-icon" onClick={handlerSidebar} />
        </Menu.Menu>

        <Menu.Menu position="right">
          <span className="header-titulo">
            <a href="https://portal.inmet.gov.br/" target="_blank" rel="noreferrer">
              Instituto Nacional de Meteorologia
            </a>
          </span>
        </Menu.Menu>

      </Menu>
      <MenuSidebar />
    </>
  );
}

export default FixedHeader;
