import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSubmitPost } from '../../contexts/SubmitPost';
import { useVisibleSidebar } from '../../contexts/VisibleSidebar';
import { useProduto } from '../../contexts/Produto';
import { useSubmit } from '../../contexts/Submit';
import AnaliseSituacaoAtual from './Analise_Sinotica/Index';
import CondicoesTempoRegistradas from './Condicoes_Tempo/Index';
import GraficosDiarios from './Graficos_Horarios/Index';
import Graficos from './Graficos_Diarios/Index';
import GraficosAnuais from './Graficos_Anuais/Index';
import CondicoesRegistradas from './Mapa_Condicoes/Index';
import Anomalias from './Anomalia_Diaria/Index';
import PrecAcumulada from './Mapa_Precipitacao/Index';
import Sondagem from './Sondagem/Index';
import TabelaEstacoes from './Tabela_Estacoes/Index';
import ValoresExtremos from './Valores_Extremos/Index';
import WSI from './Wigos/Index';
import ApiTempo from '../../services/ApiTempo';

function Content() {
  const params = useParams();
  const [produto, setProduto] = useProduto();
  const [submit, setSubmit] = useSubmit();
  const [, setSubmitPost] = useSubmitPost();
  const [, setVisible] = useVisibleSidebar();

  const ComponentsContent = {
    AnaliseSituacaoAtual,
    CondicoesTempoRegistradas,
    GraficosDiarios,
    Graficos,
    GraficosAnuais,
    CondicoesRegistradas,
    Anomalias,
    PrecAcumulada,
    Sondagem,
    TabelaEstacoes,
    ValoresExtremos,
    WSI,
  };

  const getDataCondReg = async () => {
    const response = await ApiTempo.get('/datas/CondicoesRegistradas').then((res) => res.data);
    return response[0].sigla.split('T')[0];
  };

  const getDataSondagem = async (estacao) => {
    const response = await ApiTempo.get(`/datas/Sondagem/${estacao}`).then((res) => res.data);
    return response[0].sigla.split('T')[0];
  };

  const getHoraSondagem = async (estacao, data) => {
    const response = await ApiTempo.get(`/horas/Sondagem/${estacao}/${data}`).then((res) => res.data);
    return response[0].sigla;
  };

  const getDataAnomalia = async () => {
    const response = await ApiTempo.get('/anomalias/datas/disponiveis').then((res) => res.data);
    return response[0].data.split('T')[0];
  };

  const getEstacao = async (codigo) => {
    const response = await ApiTempo.get(`/getEstacao/${codigo}`).then((res) => res.data);
    return response;
  };

  const getParametroValExtremo = async () => {
    const response = await ApiTempo.get('/parametros/ValoresExtremos').then((res) => res.data);
    return response.filter((item) => item.sigla.includes(params.parametro))[0].sigla;
  };

  const checkUrlParams = () => {
    if ('produto' in params) {
      setProduto(params.produto);
    }
  };

  const handlerSubmitPost = async () => {
    // Só faz as alterações se tiver parâmetros na URL
    if (Object.keys(params).length !== 0) {
      if (produto === 'AnaliseSituacaoAtual') {
        setSubmitPost({});
        setSubmit(true);
        setVisible(false);
      }

      if (produto === 'CondicoesTempoRegistradas') {
        const hora = parseInt(new Date().getHours(), 10);
        const data = hora < 16
          ? new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
          : new Date().toISOString().split('T')[0];
        setSubmitPost({ data });
      }

      if (produto === 'CondicoesRegistradas') {
        const parametro = 'TEMPAR';
        const data = await getDataCondReg();
        setSubmitPost({ data, parametro });
      }

      if (produto === 'PrecAcumulada') {
        const hora = parseInt(new Date().getHours(), 10);
        const data = hora >= 13
          ? new Date().toISOString().split('T')[0]
          : new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0];
        setSubmitPost({ data });
      }

      if (produto === 'Anomalias') {
        const data = await getDataAnomalia();
        setSubmitPost({ data });
      }

      if (produto === 'ValoresExtremos' && 'parametro' in params) {
        const hora = parseInt(new Date().getHours(), 10);
        const data = hora > 21
          ? new Date().toISOString().split('T')[0]
          : new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0];
        const parametro = await getParametroValExtremo();
        setSubmitPost({
          data,
          parametro,
          abrangencia: null,
          regiao: null,
        });
      }

      if (produto === 'Sondagem') {
        const data = await getDataSondagem(params.parametro);
        const hora = await getHoraSondagem(params.parametro, data);
        setSubmitPost({ estacao: params.parametro, data, hora });
      }

      if (produto === 'GraficosAnuais') {
        const estacao = await getEstacao(params.parametro);
        setSubmitPost({
          estacao: `${estacao.CD_ESTACAO}::${estacao.DC_NOME}`,
          ano: new Date().getFullYear(),
        });
      }

      if (produto === 'Graficos') {
        const estacao = await getEstacao(params.parametro);
        setSubmitPost({
          estacao: `${estacao.CD_ESTACAO}::${estacao.DC_NOME}`,
          tipoEstacao: estacao.TP_ESTACAO === 'Automatica' ? 'T' : 'M',
          ano: new Date().getFullYear(),
          mes: (`0${new Date().getMonth() + 1}`).slice(-2),
        });
      }

      if (produto === 'GraficosDiarios') {
        const estacao = await getEstacao(params.parametro);
        setSubmitPost({
          estacao: `${estacao.CD_ESTACAO}::${estacao.DC_NOME}`,
          data: new Date().toISOString().split('T')[0],
        });
      }

      if (produto === 'TabelaEstacoes') {
        const estacao = await getEstacao(params.parametro);
        setSubmitPost({
          estacao: `${estacao.CD_ESTACAO}::${estacao.DC_NOME}`,
          tipoEstacao: estacao.TP_ESTACAO === 'Automatica' ? 'T' : 'M',
          dataInicio: new Date().toISOString().split('T')[0],
          dataFim: new Date().toISOString().split('T')[0],
        });
      }
    }
  };

  useEffect(() => {
    checkUrlParams();
  }, []);

  useEffect(() => {
    handlerSubmitPost();
  }, [produto]);

  const ComponentContentRender = ComponentsContent[produto] || null;

  if (submit) {
    return <ComponentContentRender />;
  }

  return null;
}

export default Content;
