import React, { useState, useEffect } from 'react';
import {
  Button, Dropdown, Input, Icon,
} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import { useVisibleSidebar } from '../../../contexts/VisibleSidebar';
import ApiTempo from '../../../services/ApiTempo';

function ValoresExtremos() {
  const params = useParams();

  const abrangencias = [
    { key: 'null', value: 'null', text: 'País' },
    { key: 'regiao', value: 'regiao', text: 'Região' },
    { key: 'estado', value: 'estado', text: 'Estado' },
  ];

  const estados = [
    { key: 'null', value: 'null', text: 'Selecione' },
    { key: 'AC', value: 'AC', text: 'Acre' },
    { key: 'AL', value: 'AL', text: 'Alagoas' },
    { key: 'AP', value: 'AP', text: 'Amapá' },
    { key: 'AM', value: 'AM', text: 'Amazonas' },
    { key: 'BA', value: 'BA', text: 'Bahia' },
    { key: 'CE', value: 'CE', text: 'Ceará' },
    { key: 'DF', value: 'DF', text: 'Distrito Federal' },
    { key: 'ES', value: 'ES', text: 'Espírito Santo' },
    { key: 'GO', value: 'GO', text: 'Goiás' },
    { key: 'MA', value: 'MA', text: 'Maranhão' },
    { key: 'MT', value: 'MT', text: 'Mato Grosso' },
    { key: 'MS', value: 'MS', text: 'Mato Grosso do Sul' },
    { key: 'MG', value: 'MG', text: 'Minas Gerais' },
    { key: 'PA', value: 'PA', text: 'Pará' },
    { key: 'PB', value: 'PB', text: 'Paraíba' },
    { key: 'PR', value: 'PR', text: 'Paraná' },
    { key: 'PE', value: 'PE', text: 'Pernambuco' },
    { key: 'PI', value: 'PI', text: 'Piauí' },
    { key: 'RJ', value: 'RJ', text: 'Rio de Janeiro' },
    { key: 'RN', value: 'RN', text: 'Rio Grande do Norte' },
    { key: 'RS', value: 'RS', text: 'Rio Grande do Sul' },
    { key: 'RO', value: 'RO', text: 'Rondônia' },
    { key: 'RR', value: 'RR', text: 'Roraima' },
    { key: 'SC', value: 'SC', text: 'Santa Catarina' },
    { key: 'SP', value: 'SP', text: 'São Paulo' },
    { key: 'SE', value: 'SE', text: 'Sergipe' },
    { key: 'TO', value: 'TO', text: 'Tocantins' },
  ];

  const regioes = [
    { key: 'N', value: 'N', text: 'Norte' },
    { key: 'NE', value: 'NE', text: 'Nordeste' },
    { key: 'S', value: 'S', text: 'Sul' },
    { key: 'SE', value: 'SE', text: 'Sudeste' },
    { key: 'CO', value: 'CO', text: 'Centro-Oeste' },
  ];

  const hora = parseInt(new Date().getHours(), 10);
  const maxDate = hora > 21
    ? new Date().toISOString().split('T')[0]
    : new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0];

  const [parametros, setParametros] = useState([]);

  const [data, setData] = useState(maxDate);
  const [parametroSelecionado, setParametroSelecionado] = useState('TMAX_(°C)');
  const [abrangenciaSelecionada, setAbrangenciaSelecionada] = useState(null);
  const [regiaoSelecionada, setRegiaoSelecionada] = useState(null);

  const [errorData, setErrorData] = useState(false);
  const [errorAbrangencia, setErrorAbrangencia] = useState(false);
  const [errorRegiao, setErrorRegiao] = useState(false);

  const [, setSubmit] = useSubmit();
  const [submitPost, setSubmitPost] = useSubmitPost();
  const [, setVisibleSidebar] = useVisibleSidebar();

  useEffect(() => {
    ApiTempo.get('/parametros/ValoresExtremos')
      .then((response) => {
        setParametros(() => response.data.map((item) => ({
          key: item.sigla,
          text: item.nome,
          value: item.sigla,
        })));
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao carregar lista de estações.");
      });
  }, []);

  const validateForm = () => {
    if (!data) {
      setErrorData(true);
      return false;
    }

    if (!abrangenciaSelecionada) {
      setErrorAbrangencia(true);
      return false;
    }

    if (abrangenciaSelecionada !== 'null' && !regiaoSelecionada) {
      setErrorRegiao(true);
      return false;
    }

    setErrorData(false);
    setErrorAbrangencia(false);
    setErrorRegiao(false);
    return true;
  };

  const handlerSubmit = () => {
    setSubmitPost({
      data,
      parametro: parametroSelecionado,
      abrangencia: abrangenciaSelecionada,
      regiao: regiaoSelecionada,
    });
  };

  const handlerData = (event) => {
    setSubmit(false);
    setErrorData(false);
    setData(event.target.value);
  };

  useEffect(() => {
    const validate = validateForm();
    if (validate && Object.keys(submitPost).length !== 0) {
      setSubmit(true);
      setVisibleSidebar(false);
    }

    if (Object.keys(params).length !== 0) {
      setSubmit(true);
      setVisibleSidebar(false);
    }
  }, [submitPost]);

  const handlerParametro = (value) => {
    setSubmit(false);
    setParametroSelecionado(value);
  };

  const handlerAbrangencia = (event, selected) => {
    setSubmit(false);
    setErrorAbrangencia(false);
    setAbrangenciaSelecionada(selected.value);
    setRegiaoSelecionada(null);
  };

  const handlerRegiao = (event, selected) => {
    setSubmit(false);
    setErrorRegiao(false);
    setRegiaoSelecionada(selected.value);
  };

  return (
    <>
      <span className="sidebar-item-label">Data</span>
      <Input iconPosition="left" error={errorData} style={{ marginBottom: 15 }}>
        <Icon name="calendar" />
        <input type="date" max={maxDate} value={data} onChange={handlerData} />
      </Input>

      <span className="sidebar-item-label">Parâmetro</span>
      <Button.Group compact fluid size="tiny" style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 15 }}>
        {parametros.map((item) => (
          <Button
            style={{ marginBottom: 3 }}
            key={item.key}
            onClick={() => handlerParametro(item.value)}
            className={parametroSelecionado === item.value ? 'btn-active' : ''}
          >
            {item.text}
          </Button>
        ))}
      </Button.Group>

      <span className="sidebar-item-label">Abrangência</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={abrangencias}
        onChange={handlerAbrangencia}
        value={abrangenciaSelecionada}
        loading={abrangencias?.length === 0}
        style={{ marginBottom: 15 }}
        error={errorAbrangencia}
      />

      {abrangenciaSelecionada && abrangenciaSelecionada !== 'null'
        ? (
          <>
            <span className="sidebar-item-label">Região/UF</span>
            <Dropdown
              placeholder="Selecione"
              search
              selection
              options={abrangenciaSelecionada === 'regiao' ? regioes : estados}
              onChange={handlerRegiao}
              value={regiaoSelecionada}
              loading={abrangencias?.length === 0}
              style={{ marginBottom: 15 }}
              error={errorRegiao}
            />
          </>
        )
        : null}

      <Button onClick={handlerSubmit}>Gerar Tabela</Button>
    </>
  );
}

export default ValoresExtremos;
