import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import { useVisibleSidebar } from '../../../contexts/VisibleSidebar';
import ApiTempo from '../../../services/ApiTempo';

function CondicoesRegistradas() {
  const [parametros, setParametros] = useState([]);
  const [datas, setDatas] = useState([]);

  const [parametroSelecionado, setParametroSelecionado] = useState('TEMPAR');
  const [dataSelecionada, setDataSelecionada] = useState(null);

  const [errorParametro, setErrorParametro] = useState(false);
  const [errorData, setErrorData] = useState(false);

  const [, setSubmit] = useSubmit();
  const [submitPost, setSubmitPost] = useSubmitPost();
  const [, setVisibleSidebar] = useVisibleSidebar();

  const validateForm = () => {
    if (!dataSelecionada) {
      setErrorData(true);
      return false;
    }

    if (!parametroSelecionado) {
      errorParametro(true);
      return false;
    }

    setErrorParametro(false);
    setErrorData(false);
    return true;
  };

  const handlerSubmit = () => {
    setSubmitPost({ data: dataSelecionada, parametro: parametroSelecionado });
  };

  const getParametros = async () => {
    await ApiTempo.get('/parametros/CondicoesRegistradas')
      .then((response) => {
        setParametros(() => response.data.map((item) => ({
          key: item.sigla,
          text: item.nome,
          value: item.sigla,
        })));
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao carregar lista de parâmetros.");
      });
  };

  const getDatas = async () => {
    await ApiTempo.get('/datas/CondicoesRegistradas')
      .then((response) => {
        setDatas(() => response.data.map((item) => ({
          key: item.sigla,
          text: item.nome,
          value: item.sigla,
        })));
      })
      .catch((error) => {
      // eslint-disable-next-line
      console.log(error);
        // eslint-disable-next-line
      window.alert("Erro ao carregar lista de parâmetros.");
      });
  };

  useEffect(() => {
    getParametros();
    getDatas();
  }, []);

  useEffect(() => {
    const validate = validateForm();
    if (validate) {
      setSubmit(true);
      setVisibleSidebar(false);
    }
  }, [submitPost]);

  const handlerParametro = (value) => {
    setSubmit(false);
    setErrorParametro(false);
    setParametroSelecionado(value);
  };

  const handlerData = (event, selected) => {
    setSubmit(false);
    setErrorData(false);
    setDataSelecionada(selected.value);
  };

  return (
    <>
      <span className="sidebar-item-label">Parâmetro</span>
      <Button.Group compact style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 15 }}>
        {parametros.map((item) => (
          <Button
            style={{ marginBottom: 3 }}
            key={item.key}
            onClick={() => handlerParametro(item.value)}
            className={parametroSelecionado === item.value ? 'btn-active' : ''}
          >
            {item.text}
          </Button>
        ))}
      </Button.Group>

      <span className="sidebar-item-label">Data</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={datas}
        onChange={handlerData}
        value={dataSelecionada}
        loading={datas.length === 0}
        style={{ marginBottom: 15 }}
        error={errorData}
      />

      <Button onClick={handlerSubmit}>Gerar Imagem</Button>
    </>
  );
}

export default CondicoesRegistradas;
