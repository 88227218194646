import React, { useState, useEffect } from 'react';
import {
  Button, Dropdown, Input, Icon,
} from 'semantic-ui-react';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import { useVisibleSidebar } from '../../../contexts/VisibleSidebar';
import ApiTempo from '../../../services/ApiTempo';

function WSI() {
  const estados = [
    { key: null, value: null, text: 'All' },
    { key: 'AC', value: 'AC', text: 'Acre' },
    { key: 'AL', value: 'AL', text: 'Alagoas' },
    { key: 'AP', value: 'AP', text: 'Amapá' },
    { key: 'AM', value: 'AM', text: 'Amazonas' },
    { key: 'BA', value: 'BA', text: 'Bahia' },
    { key: 'CE', value: 'CE', text: 'Ceará' },
    { key: 'DF', value: 'DF', text: 'Distrito Federal' },
    { key: 'ES', value: 'ES', text: 'Espírito Santo' },
    { key: 'GO', value: 'GO', text: 'Goiás' },
    { key: 'MA', value: 'MA', text: 'Maranhão' },
    { key: 'MT', value: 'MT', text: 'Mato Grosso' },
    { key: 'MS', value: 'MS', text: 'Mato Grosso do Sul' },
    { key: 'MG', value: 'MG', text: 'Minas Gerais' },
    { key: 'PA', value: 'PA', text: 'Pará' },
    { key: 'PB', value: 'PB', text: 'Paraíba' },
    { key: 'PR', value: 'PR', text: 'Paraná' },
    { key: 'PE', value: 'PE', text: 'Pernambuco' },
    { key: 'PI', value: 'PI', text: 'Piauí' },
    { key: 'RJ', value: 'RJ', text: 'Rio de Janeiro' },
    { key: 'RN', value: 'RN', text: 'Rio Grande do Norte' },
    { key: 'RS', value: 'RS', text: 'Rio Grande do Sul' },
    { key: 'RO', value: 'RO', text: 'Rondônia' },
    { key: 'RR', value: 'RR', text: 'Roraima' },
    { key: 'SC', value: 'SC', text: 'Santa Catarina' },
    { key: 'SP', value: 'SP', text: 'São Paulo' },
    { key: 'SE', value: 'SE', text: 'Sergipe' },
    { key: 'TO', value: 'TO', text: 'Tocantins' },
  ];

  const maxDate = new Date().toISOString().split('T')[0];

  const tiposEstacoes = [
    { key: 1, text: 'Automatic', value: 'T' },
    { key: 2, text: 'Conventional', value: 'M' },
  ];

  const [estacoes, setEstacoes] = useState([]);
  const [estacoesAutomaticas, setEstacoesAutomaticas] = useState([]);
  const [estacoesConvencionais, setEstacoesConvencionais] = useState([]);

  const [tipoEstacao, setTipoEstacao] = useState(null);
  const [estacao, setEstacao] = useState(null);
  const [dataInicio, setDataInicio] = useState(maxDate);
  const [dataFim, setDataFim] = useState(maxDate);
  const [estadoSelecionado, setEstadoSelecionado] = useState(null);

  const [errorTipoEstacao, setErrorTipoEstacao] = useState(null);
  const [errorEstacao, setErrorEstacao] = useState(null);
  const [errorDataInicio, setErrorDataInicio] = useState(null);
  const [errorDataFim, setErrorDataFim] = useState(null);

  const [, setSubmit] = useSubmit();
  const [submitPost, setSubmitPost] = useSubmitPost();
  const [, setVisibleSidebar] = useVisibleSidebar();

  const handlerTipoEstacao = (event, selected) => {
    setSubmit(false);
    setErrorTipoEstacao(false);
    setTipoEstacao(selected.value);
  };

  const handlerEstacao = (event, selected) => {
    setSubmit(false);
    setErrorEstacao(false);
    setEstacao(selected.value);
  };

  const handlerSubmit = () => {
    setSubmitPost({
      tipoEstacao, estacao, dataInicio, dataFim,
    });
  };

  const validateForm = () => {
    if (!tipoEstacao) {
      setErrorTipoEstacao(true);
      return false;
    }

    if (!estacao) {
      setErrorEstacao(true);
      return false;
    }

    if (!dataInicio) {
      setErrorDataInicio(true);
      return false;
    }

    if (!dataFim) {
      errorDataFim(true);
      return false;
    }

    setErrorTipoEstacao(false);
    setErrorEstacao(false);
    setErrorDataInicio(false);
    setErrorDataFim(false);
    return true;
  };

  useEffect(() => {
    setSubmit(false);
    setEstacao(null);
    setEstacoes(tipoEstacao === 'T' ? estacoesAutomaticas : estacoesConvencionais);
  }, [tipoEstacao]);

  const getEstacoes = async (tipo) => {
    await ApiTempo.get(`/estacoes/${tipo}`)
      .then((response) => {
        const tempEstacoes = response.data.map((item) => ({
          key: item.CD_ESTACAO,
          text: `${item.CD_WSI} (${item.DC_NOME})`,
          value: `${item.CD_ESTACAO}::${item.DC_NOME}::${item.CD_WSI}`,
          estado: item.SG_ESTADO,
        }));

        if (tipo === 'T') {
          setEstacoesAutomaticas(tempEstacoes);
        }

        if (tipo === 'M') {
          setEstacoesConvencionais(tempEstacoes);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao carregar lista de estações.");
      });
  };

  useEffect(() => {
    getEstacoes('T');
    getEstacoes('M');
  }, []);

  useEffect(() => {
    const validate = validateForm();
    if (validate && Object.keys(submitPost).length !== 0) {
      setSubmit(true);
      setVisibleSidebar(false);
    }
  }, [submitPost]);

  const handlerDataInicio = (event) => {
    setSubmit(false);
    setErrorDataInicio(false);
    setDataInicio(event.target.value);
  };

  const handlerDataFim = (event) => {
    setSubmit(false);
    setErrorDataFim(false);
    setDataFim(event.target.value);
  };

  const handlerEstado = (event, selected) => {
    setSubmit(false);
    setEstacao(null);
    setEstadoSelecionado(selected.value);
  };

  return (
    <>
      <span className="sidebar-item-label">Station&apos;s Type</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={tiposEstacoes}
        onChange={handlerTipoEstacao}
        value={tipoEstacao}
        loading={tipoEstacao?.length === 0}
        style={{ marginBottom: 15 }}
        error={errorTipoEstacao}
      />

      <span className="sidebar-item-label">State</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={estados}
        onChange={handlerEstado}
        value={estadoSelecionado}
        loading={estados.length === 0}
        style={{ marginBottom: 15 }}
      />

      <span className="sidebar-item-label">Station</span>
      <Dropdown
        placeholder="Selecione"
        search
        selection
        options={estadoSelecionado
          ? estacoes.filter((item) => item.estado === estadoSelecionado)
          : estacoes}
        onChange={handlerEstacao}
        value={estacao}
        loading={estacao?.length === 0}
        style={{ marginBottom: 15 }}
        error={errorEstacao}
      />

      <span className="sidebar-item-label">Start Date</span>
      <Input iconPosition="left" error={errorDataInicio} style={{ marginBottom: 15 }}>
        <Icon name="calendar" />
        <input type="date" max={maxDate} value={dataInicio} onChange={handlerDataInicio} />
      </Input>

      <span className="sidebar-item-label">End Date</span>
      <Input iconPosition="left" error={errorDataFim} style={{ marginBottom: 15 }}>
        <Icon name="calendar" />
        <input type="date" max={maxDate} value={dataFim} onChange={handlerDataFim} />
      </Input>

      <Button onClick={handlerSubmit}>Generate Table</Button>
    </>
  );
}

export default WSI;
