import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Input, Icon, Table, Button,
} from 'semantic-ui-react';
import atob from 'atob';
import btoa from 'btoa';
import randomstring from 'randomstring';
import { CSVLink } from 'react-csv';
import ApiMapas from '../../services/ApiMapas';
import ApiTempo from '../../services/ApiTempo';
import Loading from '../../components/Loading/Index';

import './Style.css';

function Index() {
  const params = useParams();
  const maxDate = new Date().toISOString().split('T')[0];

  const [estacao, setEstacao] = useState(null);
  const [tipoEstacao, setTipoEstacao] = useState(null);
  const [dataInicio, setDataInicio] = useState(params.data);
  const [errorDataInicio, setErrorDataInicio] = useState(false);
  const [dataFim, setDataFim] = useState(params.data);
  const [errorDataFim, setErrorDataFim] = useState(false);
  const [tabela, setTabela] = useState([]);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csv, setCsv] = useState([]);

  const handlerDataInicio = (event) => {
    setErrorDataInicio(false);
    setDataInicio(event.target.value);
  };

  const handlerDataFim = (event) => {
    setErrorDataFim(false);
    setDataFim(event.target.value);
  };

  const getEstacao = async () => {
    await ApiMapas.get(`/entidade/info/${params.estacao}`)
      .then((response) => (
        setEstacao({ codigo: params.estacao, entidade: response.data[0].SG_ENTIDADE })));
  };

  const getDados = async () => {
    /* eslint-disable camelcase */
    const vro = btoa(randomstring.generate(50));
    const dec = atob(vro);
    // eslint-disable-next-line
    var decvro = window.btoa(randomstring.generate(50))
    // eslint-disable-next-line
    decvro = decvro.slice(0,-1)
    // eslint-disable-next-line
    const seed = dec + '&' + decvro + vro
    const data_inicio = dataInicio;
    const data_fim = dataFim;
    // eslint-disable-next-line
    window.grecaptcha.ready(function() {
    // eslint-disable-next-line
      window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(token) {
        // eslint-disable-next-line
        await ApiTempo.post('/estacao/front/', { data_inicio, data_fim, estacao : estacao.codigo, seed, gcap: token })
          .then((response) => setDados(response.data))
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
            // eslint-disable-next-line
            window.alert("Erro ao carregar dados.");
          });
      });
    });
  };

  const formatarDadosAutomatica = () => {
    const tempTable = (
      <Table color="blue" unstackable>
        <Table.Header>
          <Table.Row key={Math.random()}>
            <Table.HeaderCell textAlign="center">Data</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Hora</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan={3}>Temperatura (°C)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan={3}>Umidade (%)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan={3}>Pto. Orvalho (°C)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan={3}>Pressão (hPa)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan={3}>Vento</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Radiação</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Chuva</Table.HeaderCell>
          </Table.Row>
          <Table.Row key={Math.random()}>
            <Table.HeaderCell textAlign="center">{' '}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">UTC</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Inst.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Máx.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Mín.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Inst.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Máx.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Mín.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Inst.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Máx.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Mín.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Inst.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Máx.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Mín.</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Vel. (m/s)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Dir. (°)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Raj. (m/s)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Kj/m²</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">mm</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {dados.map((item) => (
            <Table.Row key={Math.random()}>
              <Table.Cell textAlign="center">{item.DT_MEDICAO ? item.DT_MEDICAO.split('-').reverse().join('/') : ''}</Table.Cell>
              <Table.Cell textAlign="center">{item.HR_MEDICAO ? item.HR_MEDICAO : ''}</Table.Cell>

              <Table.Cell textAlign="right">{item.TEM_INS ? parseFloat(item.TEM_INS).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.TEM_MAX ? parseFloat(item.TEM_MAX).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.TEM_MIN ? parseFloat(item.TEM_MIN).toFixed(1).replace('.', ',') : ''}</Table.Cell>

              <Table.Cell textAlign="right">{item.UMD_INS ? parseFloat(item.UMD_INS).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.UMD_MAX ? parseFloat(item.UMD_MAX).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.UMD_MIN ? parseFloat(item.UMD_MIN).toFixed(1).replace('.', ',') : ''}</Table.Cell>

              <Table.Cell textAlign="right">{item.PTO_INS ? parseFloat(item.PTO_INS).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.PTO_MAX ? parseFloat(item.PTO_MAX).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.PTO_MIN ? parseFloat(item.PTO_MIN).toFixed(1).replace('.', ',') : ''}</Table.Cell>

              <Table.Cell textAlign="right">{item.PRE_INS ? parseFloat(item.PRE_INS).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.PRE_MAX ? parseFloat(item.PRE_MAX).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.PRE_MIN ? parseFloat(item.PRE_MIN).toFixed(1).replace('.', ',') : ''}</Table.Cell>

              <Table.Cell textAlign="right">{item.VEN_VEL ? parseFloat(item.VEN_VEL).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.VEN_DIR ? parseFloat(item.VEN_DIR).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.VEN_RAJ ? parseFloat(item.VEN_RAJ).toFixed(1).replace('.', ',') : ''}</Table.Cell>

              <Table.Cell textAlign="right">{item.RAD_GLO && parseFloat(item.RAD_GLO) > 0 ? parseFloat(item.RAD_GLO).toFixed(2).replace('.', ',') : ''}</Table.Cell>

              <Table.Cell textAlign="right">{item.CHUVA ? parseFloat(item.CHUVA).toFixed(1).replace('.', ',') : ''}</Table.Cell>

            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
    setTabela(tempTable);
  };

  const formatarDadosConvencional = () => {
    const tempTable = (
      <Table color="blue" unstackable>
        <Table.Header>
          <Table.Row key={Math.random()}>
            <Table.HeaderCell textAlign="center">Data</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Hora</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Temperatura</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Umidade</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Pressão</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan={2}>Vento</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Nebulosidade</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Insolação</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan={3}>Dados Diários</Table.HeaderCell>
          </Table.Row>
          <Table.Row key={Math.random()}>
            <Table.HeaderCell textAlign="center">{' '}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">UTC</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">°C</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">%</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">hPa</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Vel. (m/s)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Dir. (°)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Décimos</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">h</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Temp. Máx. (°C)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Temp. Mín. (°C)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Chuva (mm)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {dados.map((item) => (
            <Table.Row key={Math.random()}>
              <Table.Cell textAlign="center">{item.DT_MEDICAO ? item.DT_MEDICAO.split('-').reverse().join('/') : ''}</Table.Cell>
              <Table.Cell textAlign="center">{item.HR_MEDICAO ? item.HR_MEDICAO : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.TEMP_HORA ? parseFloat(item.TEMP_HORA).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.UMID_HORA ? parseFloat(item.UMID_HORA).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.PRESS_EST ? parseFloat(item.PRESS_EST).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.VENT_VEL ? parseFloat(item.VENT_VEL).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.VENT_DIR ? parseFloat(item.VENT_DIR).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.NEBU_HORA ? parseFloat(item.NEBU_HORA).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.INSO_HORA ? parseFloat(item.INSO_HORA).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.TEMP_MAX ? parseFloat(item.TEMP_MAX).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.TEMP_MIN ? parseFloat(item.TEMP_MIN).toFixed(1).replace('.', ',') : ''}</Table.Cell>
              <Table.Cell textAlign="right">{item.CHUVA ? parseFloat(item.CHUVA).toFixed(1).replace('.', ',') : ''}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
    setTabela(tempTable);
  };

  const criarCSVAutomatica = async () => {
    const csvData = [];
    csvData.push([
      'Data',
      'Hora (UTC)',
      'Temp. Ins. (C)',
      'Temp. Max. (C)',
      'Temp. Min. (C)',
      'Umi. Ins. (%)',
      'Umi. Max. (%)',
      'Umi. Min. (%)',
      'Pto Orvalho Ins. (C)',
      'Pto Orvalho Max. (C)',
      'Pto Orvalho Min. (C)',
      'Pressao Ins. (hPa)',
      'Pressao Max. (hPa)',
      'Pressao Min. (hPa)',
      'Vel. Vento (m/s)',
      'Dir. Vento (m/s)',
      'Raj. Vento (m/s)',
      'Radiacao (KJ/m²)',
      'Chuva (mm)',
    ]);

    dados.map((dado) => csvData.push([
      `${dado.DT_MEDICAO ? dado.DT_MEDICAO.split('-').reverse().join('/') : ''}`,
      `${dado.HR_MEDICAO ? dado.HR_MEDICAO : ''}`,
      `${dado.TEM_INS ? parseFloat(dado.TEM_INS).toFixed(1).replace('.', ',') : ''}`,
      `${dado.TEM_MAX ? parseFloat(dado.TEM_MAX).toFixed(1).replace('.', ',') : ''}`,
      `${dado.TEM_MIN ? parseFloat(dado.TEM_MIN).toFixed(1).replace('.', ',') : ''}`,
      `${dado.UMD_INS ? parseFloat(dado.UMD_INS).toFixed(1).replace('.', ',') : ''}`,
      `${dado.UMD_MAX ? parseFloat(dado.UMD_MAX).toFixed(1).replace('.', ',') : ''}`,
      `${dado.UMD_MIN ? parseFloat(dado.UMD_MIN).toFixed(1).replace('.', ',') : ''}`,
      `${dado.PTO_INS ? parseFloat(dado.PTO_INS).toFixed(1).replace('.', ',') : ''}`,
      `${dado.PTO_MAX ? parseFloat(dado.PTO_MAX).toFixed(1).replace('.', ',') : ''}`,
      `${dado.PTO_MIN ? parseFloat(dado.PTO_MIN).toFixed(1).replace('.', ',') : ''}`,
      `${dado.PRE_INS ? parseFloat(dado.PRE_INS).toFixed(1).replace('.', ',') : ''}`,
      `${dado.PRE_MAX ? parseFloat(dado.PRE_MAX).toFixed(1).replace('.', ',') : ''}`,
      `${dado.PRE_MIN ? parseFloat(dado.PRE_MIN).toFixed(1).replace('.', ',') : ''}`,
      `${dado.VEN_VEL ? parseFloat(dado.VEN_VEL).toFixed(1).replace('.', ',') : ''}`,
      `${dado.VEN_DIR ? parseFloat(dado.VEN_DIR).toFixed(1).replace('.', ',') : ''}`,
      `${dado.VEN_RAJ ? parseFloat(dado.VEN_RAJ).toFixed(1).replace('.', ',') : ''}`,
      `${dado.RAD_GLO && parseFloat(dado.RAD_GLO) > 0 ? parseFloat(dado.RAD_GLO).toFixed(2).replace('.', ',') : ''}`,
      `${dado.CHUVA ? parseFloat(dado.CHUVA).toFixed(1).replace('.', ',') : ''}`,
    ]));
    setCsv(csvData);
  };

  const criarCSVConvencional = async () => {
    const csvData = [];
    csvData.push([
      'Data',
      'Hora (UTC)',
      'Temp. [Hora] (C)',
      'Umi. (%)',
      'Pressao (hPa)',
      'Vel. Vento (m/s)',
      'Dir. Vento (m/s)',
      'Nebulosidade (Decimos)',
      'Insolacao (h)',
      'Temp. Max. [Diaria] (h)',
      'Temp. Min. [Diaria] (h)',
      'Chuva [Diaria] (mm)',
    ]);

    dados.map((dado) => csvData.push([
      `${dado.DT_MEDICAO ? dado.DT_MEDICAO.split('-').reverse().join('/') : ''}`,
      `${dado.HR_MEDICAO ? dado.HR_MEDICAO : ''}`,
      `${dado.TEMP_HORA ? parseFloat(dado.TEMP_HORA).toFixed(1).replace('.', ',') : ''}`,
      `${dado.UMID_HORA ? parseFloat(dado.UMID_HORA).toFixed(1).replace('.', ',') : ''}`,
      `${dado.PRESS_EST ? parseFloat(dado.PRESS_EST).toFixed(1).replace('.', ',') : ''}`,
      `${dado.VENT_VEL ? parseFloat(dado.VENT_VEL).toFixed(1).replace('.', ',') : ''}`,
      `${dado.VENT_DIR ? parseFloat(dado.VENT_DIR).toFixed(1).replace('.', ',') : ''}`,
      `${dado.NEBU_HORA ? parseFloat(dado.NEBU_HORA).toFixed(1).replace('.', ',') : ''}`,
      `${dado.INSO_HORA ? parseFloat(dado.INSO_HORA).toFixed(2).replace('.', ',') : ''}`,
      `${dado.TEMP_MAX ? parseFloat(dado.TEMP_MAX).toFixed(1).replace('.', ',') : ''}`,
      `${dado.TEMP_MIN ? parseFloat(dado.TEMP_MIN).toFixed(1).replace('.', ',') : ''}`,
      `${dado.CHUVA ? parseFloat(dado.CHUVA).toFixed(1).replace('.', ',') : ''}`,
    ]));
    setCsv(csvData);
  };

  const handlerSubmit = () => setDados([]);

  useEffect(() => {
    if (Object.keys(params).length !== 0) {
      if ('estacao' in params && 'data' in params) {
        getEstacao();
      }
    }
  }, []);

  useEffect(() => {
    if (estacao) {
      if (estacao.codigo.charAt(0) === 'A'
        || estacao.codigo.charAt(0) === 'S'
        || estacao.codigo.charAt(0) === 'V'
        || estacao.codigo.charAt(0) === 'F'
        || estacao.codigo.charAt(0) === 'C'
        || estacao.codigo.charAt(0) === 'I'
        || estacao.codigo.charAt(0) === 'B') {
        setTipoEstacao('T');
      } else {
        setTipoEstacao('M');
      }
    }
  }, [estacao]);

  useEffect(() => {
    if (dados.length !== 0) {
      if (tipoEstacao === 'T') {
        formatarDadosAutomatica();
        criarCSVAutomatica();
      }

      if (tipoEstacao === 'M') {
        formatarDadosConvencional();
        criarCSVConvencional();
      }
    } else if (dados.length === 0 && tipoEstacao) {
      setLoading(true);
      getDados();
    }
  }, [dados, tipoEstacao]);

  useEffect(() => {
    if (tabela) {
      setLoading(false);
    }
  }, [tabela]);

  if (!loading) {
    return (
      <div className="content-tabela">
        <div className="form">
          <div className="form-item">
            <span className="sidebar-item-label">Data Início</span>
            <Input iconPosition="left" error={errorDataInicio} style={{ marginBottom: 15 }}>
              <Icon name="calendar" />
              <input type="date" max={maxDate} value={dataInicio} onChange={handlerDataInicio} />
            </Input>
          </div>

          <div className="form-item">
            <span className="sidebar-item-label">Data Fim</span>
            <Input iconPosition="left" error={errorDataFim} style={{ marginBottom: 15 }}>
              <Icon name="calendar" />
              <input type="date" max={maxDate} value={dataFim} onChange={handlerDataFim} />
            </Input>
          </div>

          <div id="buttons" className="form-item">
            <Button onClick={handlerSubmit}>Gerar Tabela</Button>
            {csv && csv.length !== 0 ? (
              <CSVLink
                data={csv}
                className="ui button"
                filename="tabela.csv"
                target="_blank"
                separator=";"
                style={{ backgroundColor: '#0F4098', color: 'white' }}
              >
                Baixar CSV
              </CSVLink>
            ) : null}
          </div>
        </div>
        {tabela}
      </div>
    );
  }

  return <Loading />;
}

export default Index;
