/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable indent */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { Table, Image } from 'semantic-ui-react';
import { useProduto } from '../../../contexts/Produto';
import { useSubmit } from '../../../contexts/Submit';
import ApiPrevmet from '../../../services/ApiPrevmet';
import ApiCommet from '../../../services/ApiCommet';
import Loading from '../../Loading/Index';

import './Style.css';

function AnaliseSituacaoAtual() {
  const [analises, setAnalises] = useState([]);
  const [produto] = useProduto();
  const [submit] = useSubmit();

  const getCartas = async (dados) => {
    for (let i = 0; i < dados.length; i += 1) {
      await ApiCommet.get(`/ANALISE/AS/analise/${dados[i].data.split('T')[0]}/${dados[i].hora}:00`)
      .then((response) => {
        if (response.data.length !== 0) {
          dados[i].carta = response.data[0];
        }
      });
    }
    setAnalises(dados);
  };

  const getAnalises = async () => {
    await ApiPrevmet.get('/analises')
      .then((response) => getCartas(response.data))
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao carregar lista de produtos.");
      });
  };

  useEffect(() => {
    getAnalises();
  }, []);

  if (produto === 'AnaliseSituacaoAtual' && submit && analises.length !== 0) {
    return (
      <>
        {analises.map((item) => (
          <Table color="blue" key={Math.random()}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Descrição</Table.HeaderCell>
                <Table.HeaderCell style={{ wordWrap: 'break-word', textAlign: 'justify' }}>{item.descricao}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>Data</Table.Cell>
                <Table.Cell>{item.data?.split('T')[0].split('-').reverse().join('/')}</Table.Cell>
              </Table.Row>
            </Table.Body>

            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Hora</Table.HeaderCell>
                <Table.HeaderCell>{`${item.hora} - UTC`}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>Meteorologista</Table.Cell>
                <Table.Cell>{item.responsavel}</Table.Cell>
              </Table.Row>
            </Table.Body>

            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Carta</Table.HeaderCell>
                <Table.HeaderCell>
                  {/* eslint-disable-next-line */}
                  {item.carta ?
                    <Image
                      // as={"a", style={{ maxWidth: '25%' }}}
                      href={item.carta.base64}
                      src={item.carta.base64}
                      target="_blank"
                      download={`${item.carta.nome}.png`}
                      alt="Carta da Análise Sinótica"
                      rounded
                      size="medium"
                      className="carta-img"
                    />
                  : 'Carta da Análise Sinótica não disponível.'}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          </Table>
        ))}
      </>
    );
  }

  return <Loading />;
}

export default AnaliseSituacaoAtual;
