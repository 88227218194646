import React, { useState, useEffect } from 'react';
import { Button, Input, Icon } from 'semantic-ui-react';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import { useVisibleSidebar } from '../../../contexts/VisibleSidebar';

function PrecAcumulada() {
  const hora = parseInt(new Date().getHours(), 10);
  const maxDate = hora >= 13
    ? new Date().toISOString().split('T')[0]
    : new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0];
  const [data, setData] = useState(maxDate);
  const [errorData, setErrorData] = useState(false);

  const [, setSubmit] = useSubmit();
  const [submitPost, setSubmitPost] = useSubmitPost();
  const [, setVisibleSidebar] = useVisibleSidebar();

  const validateForm = () => {
    if (!data) {
      setErrorData(true);
      return false;
    }

    setErrorData(false);
    return true;
  };

  const handlerSubmit = () => {
    setSubmitPost({ data });
  };

  const handlerData = (event) => {
    setErrorData(false);
    setData(event.target.value);
  };

  useEffect(() => {
    const validate = validateForm();
    if (validate && Object.keys(submitPost).length !== 0) {
      setSubmit(true);
      setVisibleSidebar(false);
    }
  }, [submitPost]);

  return (
    <>
      <span className="sidebar-item-label">Data</span>
      <Input iconPosition="left" error={errorData} style={{ marginBottom: 15 }}>
        <Icon name="calendar" />
        <input type="date" max={maxDate} value={data} onChange={handlerData} />
      </Input>

      <Button onClick={handlerSubmit}>Gerar Imagem</Button>
    </>
  );
}

export default PrecAcumulada;
