/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import atob from 'atob';
import btoa from 'btoa';
import randomstring from 'randomstring';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ApiMapas from '../../services/ApiMapas';
import Loading from '../../components/Loading/Index';

import './Style.css';

function Index() {
  const params = useParams();

  const options = [
    { key: 1, text: 'Precipitação', value: 1 },
    { key: 2, text: 'Precipitação Diária', value: 2 },
    { key: 3, text: 'Precipitação Mensal', value: 3 },
  ];

  const [option, setOption] = useState({ key: 1, text: 'Temperatura', value: 1 });
  const [graficos, setGraficos] = useState([]);
  const [estacao, setEstacao] = useState(null);
  const [loading, setLoading] = useState(false);
  const [grafico, setGrafico] = useState(null);

  const handlerOption = (event, target) => setOption(target);

  const getUltimoDiaMes = (ano, mes) => new Date((new Date(ano, mes, 1)) - 1).getDate();

  const montarGraficos = async () => {
    const [ano, mes] = params.data.split('-');

    const optionsGraficoChuva = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      chart: { type: 'column' },
      subtitle: { text: 'Precipitação Acumulada por Hora' },
      yAxis: { title: { text: 'Chuva em mm' } },
      tooltip: { valueSuffix: 'mm' },
      xAxis: {
        categories: [],
        title: { text: 'Horas' },
      },
      series: [{ name: 'Chuva em (mm)', data: [] }],
    };

    const optionsGraficoChuvaDiaria = {
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      chart: { type: 'column' },
      subtitle: { text: 'Precipitação Diária' },
      yAxis: { title: { text: 'Chuva em mm' } },
      tooltip: { valueSuffix: 'mm' },
      xAxis: {
        categories: [],
        labels: { step: 1 },
        title: { text: 'Dias do Mês' },
      },
      plotOptions: { series: { pointStart: 1 } },
      series: [{ name: 'Chuva em (mm)', data: [] }],
    };

    const optionsGraficoChuvaMensal = {
      chart: { type: 'column' },
      title: { text: 'Instituto Nacional de Meteorologia - INMET' },
      subtitle: { text: 'Precipitação Acumulada Mensal' },
      yAxis: { title: { text: 'Chuva em mm' } },
      tooltip: { valueSuffix: 'mm' },
      series: [{ name: 'Chuva em (mm)', data: [] }],
      xAxis: {
        categories: [
          `Janeiro/${ano}`,
          `Fevereiro/${ano}`,
          `Março/${ano}`,
          `Abril/${ano}`,
          `Maio/${ano}`,
          `Junho/${ano}`,
          `Julho/${ano}`,
          `Agosto/${ano}`,
          `Setembro/${ano}`,
          `Outubro/${ano}`,
          `Novembro/${ano}`,
          `Dezembro/${ano}`,
        ],
        title: { text: 'Meses' },
      },
    };
    /* eslint-disable camelcase */
    const vro = btoa(randomstring.generate(50));
    const dec = atob(vro);
    // eslint-disable-next-line
    var decvro = window.btoa(randomstring.generate(50))
    // eslint-disable-next-line
    decvro = decvro.slice(0,-1)
    // eslint-disable-next-line
    const seed = dec + '&' + decvro + vro
    const data_inicio = params.data;
    const data_fim = params.data;
    // eslint-disable-next-line
    window.grecaptcha.ready(function() {
    // eslint-disable-next-line
      window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(token) {
      // eslint-disable-next-line
      const dadosHorarios = await ApiMapas.post('/dado/estacao/',{ data_inicio, data_fim, atributo : 'I175', estacao: estacao.codigo, seed: seed, gcap: token })
          .then((response) => response.data);
        for (let i = 0; i < dadosHorarios.length; i += 1) {
          const dado = dadosHorarios[i];
          optionsGraficoChuva.title.text = `${estacao.entidade} - ${estacao.codigo}`;
          optionsGraficoChuva.xAxis.categories.push(dado.HR_MEDICAO?.slice(0, 2));
          optionsGraficoChuva.series[0].data.push(parseFloat(dado.VL_MEDICAO ? dado.VL_MEDICAO.replace(',', '.') : 0.0));
        }
      });
    });

    /* eslint-disable camelcase */
    const vro2 = btoa(randomstring.generate(50));
    const dec2 = atob(vro2);
    // eslint-disable-next-line
    var decvro2 = window.btoa(randomstring.generate(50))
    // eslint-disable-next-line
    decvro2 = decvro2.slice(0,-1)
    // eslint-disable-next-line
    const seed2 = dec2 + '&' + decvro2 + vro2
    const dataInicio = `${ano}-${mes}-01`;
    const dataFim = `${ano}-${mes}-${getUltimoDiaMes(ano, mes)}`;
    // eslint-disable-next-line
    window.grecaptcha.ready(function() {
    // eslint-disable-next-line
      window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(token) {
      // eslint-disable-next-line
      const dadosDiarios = await ApiMapas.post('/dado/estacao/',{ data_inicio : dataInicio, data_fim : dataFim, atributo : 'I006', estacao: estacao.codigo, seed: seed2, gcap: token })
          .then((response) => response.data);
        for (let i = 0; i < dadosDiarios.length; i += 1) {
          const dado = dadosDiarios[i];
          optionsGraficoChuvaDiaria.title.text = `${estacao.entidade} - ${estacao.codigo}`;
          optionsGraficoChuvaDiaria.series[0].data.push(
            parseFloat(dado.VL_MEDICAO ? dado.VL_MEDICAO.replace(',', '.') : 0.0),
          );
        }
      });
    });

    /* eslint-disable camelcase */
    const vro3 = btoa(randomstring.generate(50));
    const dec3 = atob(vro3);
    // eslint-disable-next-line
    var decvro3 = window.btoa(randomstring.generate(50))
    // eslint-disable-next-line
    decvro3 = decvro3.slice(0,-1)
    // eslint-disable-next-line
    const seed3 = dec3 + '&' + decvro3 + vro3
    const dtInicio = `${ano}-01-01`;
    const dtFim = `${ano}-12-31`;
    // eslint-disable-next-line
    window.grecaptcha.ready(function() {
    // eslint-disable-next-line
      window.grecaptcha.execute('6LdqhOkjAAAAAHqjdF9WSjtatHC0bEohqmvrADrL', {action: 'create_comment'}).then(async function(token) {
      // eslint-disable-next-line
      const dadosMensais = await ApiMapas.post('/dado/estacao/',{ data_inicio : dtInicio, data_fim : dtFim, atributo : 'I209', estacao: estacao.codigo, seed: seed3, gcap: token })
          .then((response) => response.data);
        for (let i = 0; i < dadosMensais.length; i += 1) {
          const dado = dadosMensais[i];
          optionsGraficoChuvaMensal.title.text = `${estacao.entidade} - ${estacao.codigo}`;
          optionsGraficoChuvaMensal.series[0].data.push(dado.VL_MEDICAO ? parseFloat(dado.VL_MEDICAO.replace(',', '.')) : 0);
        }
        setGraficos([
          optionsGraficoChuva,
          optionsGraficoChuvaDiaria,
          optionsGraficoChuvaMensal,
        ]);
      });
    });
  };

  const getEstacao = async () => {
    await ApiMapas.get(`/entidade/info/${params.estacao}`)
      .then((response) => (
        setEstacao({ codigo: params.estacao, entidade: response.data[0].SG_ENTIDADE })));
  };

  useEffect(() => {
    if (Object.keys(params).length !== 0) {
      if ('estacao' in params && 'data' in params) {
        getEstacao();
      }
    }
  }, []);

  useEffect(() => {
    if (estacao) {
      montarGraficos();
    }
  }, [estacao]);

  useEffect(() => {
    if ((graficos.length !== 0 && option) || loading) {
      setGrafico(graficos[option.value - 1]);
    }
  }, [graficos, loading]);

  useEffect(() => {
    if (grafico) {
      setLoading(false);
    }
  }, [grafico]);

  useEffect(() => {
    setLoading(true);
  }, [option]);

  if (!loading) {
    return (
      <div className="content">
        <Dropdown
          loading={options?.length === 0}
          value={option.value}
          onChange={handlerOption}
          options={options}
          simple
          item
          className="dropdown-grafico"
        />
        {graficos[option.value - 1]
          ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={graficos[option.value - 1]}
              containerProps={{ className: 'graficoContainer' }}
            />
          )
          : <span>Gráfico não disponível.</span>}
      </div>
    );
  }

  return <Loading />;
}

export default Index;
