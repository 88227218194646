/* eslint-disable react/jsx-props-no-spreading */
import React, {
  createContext, useContext, useState, useMemo,
} from 'react';

const Produto = createContext();

function useProduto() {
  const context = useContext(Produto);

  if (!context) {
    // eslint-disable-next-line no-alert
    window.alert('Produto não foi carregado corretamente, tente atualizar a página.');
  }

  return context;
}

function ProdutoProvider(props) {
  const [selecionado, setSelecionado] = useState(null);
  const value = useMemo(() => [selecionado, setSelecionado], [selecionado]);
  return <Produto.Provider value={value} {...props} />;
}

export { useProduto, ProdutoProvider };
