/* eslint-disable react/jsx-props-no-spreading */
import React, {
  createContext, useContext, useState, useMemo,
} from 'react';

const VisibleSidebar = createContext();

function useVisibleSidebar() {
  const context = useContext(VisibleSidebar);

  if (!context) {
    // eslint-disable-next-line no-alert
    window.alert('Ocorreu um erro ao mostrar o menu, tente atualizar a página.');
  }

  return context;
}

function VisibleSidebarProvider(props) {
  const [visible, setVisible] = useState(true);
  const value = useMemo(() => [visible, setVisible], [visible]);
  return <VisibleSidebar.Provider value={value} {...props} />;
}

export { useVisibleSidebar, VisibleSidebarProvider };
