/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'semantic-ui-react';
import { useSubmit } from '../../../contexts/Submit';
import { useVisibleSidebar } from '../../../contexts/VisibleSidebar';

function AnaliseSituacaoAtual() {
  const [, setSubmit] = useSubmit();
  const [, setVisibleSidebar] = useVisibleSidebar();

  const handlerSubmit = () => {
    setVisibleSidebar(false);
    setSubmit(true);
  };

  return <Button onClick={handlerSubmit}>Gerar Análise</Button>;
}

export default AnaliseSituacaoAtual;
