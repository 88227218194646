import React from 'react';
import Header from '../components/Header/Index';
import AppContextProvider from '../contexts/Providers';

function Index() {
  return (
    <AppContextProvider>
      <Header />
    </AppContextProvider>
  );
}

export default Index;
