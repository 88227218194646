import React, { useState, useEffect } from 'react';
import {
  Image, Divider, Button, Icon,
} from 'semantic-ui-react';
import { useProduto } from '../../../contexts/Produto';
import { useSubmit } from '../../../contexts/Submit';
import { useSubmitPost } from '../../../contexts/SubmitPost';
import ApiTempo from '../../../services/ApiTempo';
import Loading from '../../Loading/Index';

import './Style.css';

function CondicoesRegistradas() {
  const [horarios, setHorarios] = useState([]);
  const [horarioSelecionado, setHorarioSelecionado] = useState(null);
  const [imagem, setImagem] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [produto] = useProduto();
  const [submit] = useSubmit();
  const [submitPost] = useSubmitPost();

  const getHorarios = async () => {
    if ('data' in submitPost && 'parametro' in submitPost) {
      await ApiTempo.get(`/horarios/CondicoesRegistradas/${submitPost.data}`)
        .then((response) => {
          setHorarios(response.data);
          setHorarioSelecionado(null);
          setHorarioSelecionado(response.data[response.data.length - 1].sigla);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          // eslint-disable-next-line
          window.alert("Erro ao buscar horários da imagem.");
        });
    }
  };

  const getImagem = async () => {
    await ApiTempo.get(`/CondicoesRegistradas/${submitPost.parametro}/${submitPost.data}/${horarioSelecionado}`)
      .then((response) => setImagem(response.data.base64))
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        // eslint-disable-next-line
        window.alert("Erro ao buscar imagem.");
      });
  };

  useEffect(() => {
    if (horarioSelecionado) {
      getImagem();
    }
  }, [horarioSelecionado]);

  useEffect(() => {
    getHorarios();
  }, [submitPost]);

  const handlerHorario = (horario) => {
    setHorarioSelecionado(horario);
  };

  const handlerFullscreen = () => {
    if (!fullscreen) {
      const element = document.getElementById('content-fullscreen');
      const tmp = (
        element.mozRequestFullScreen
        || element.webkitRequestFullscreen // Safari
        || element.requestFullscreen // IE11
      );
      tmp.call(element);
    } else {
      document.exitFullscreen();
    }

    setFullscreen(!fullscreen);
  };

  if (produto === 'CondicoesRegistradas' && submit && Object.keys(submitPost) !== 0 && horarios && horarios.length !== 0) {
    return (
      <div className="content" id="content-fullscreen">
        <Image src={imagem} size={fullscreen ? 'huge' : 'big'} rounded />

        <Button icon size="massive" onClick={handlerFullscreen} className="fullscreen-button">
          <Icon name={fullscreen ? 'compress' : 'expand'} />
        </Button>

        <Divider horizontal>Horários (UTC)</Divider>
        <Button.Group compact fluid style={{ display: 'flex', flexWrap: 'wrap' }}>
          {horarios.map((item) => (
            <Button
              key={item.sigla}
              onClick={() => handlerHorario(item.sigla)}
              className={item.sigla === horarioSelecionado ? 'btn-active' : ''}
            >
              {item.nome}
            </Button>
          ))}
        </Button.Group>
      </div>
    );
  }

  return <Loading />;
}

export default CondicoesRegistradas;
