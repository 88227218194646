import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ApiMapas from '../../services/ApiMapas';
import Automaticas from './Automaticas';
import Convencionais from './Convencionais';

import './Style.css';

function Index() {
  const params = useParams();

  const [estacao, setEstacao] = useState(null);
  const [tipoEstacao, setTipoEstacao] = useState(null);

  const getEstacao = async () => {
    await ApiMapas.get(`/entidade/info/${params.estacao}`)
      .then((response) => (
        setEstacao({ codigo: params.estacao, entidade: response.data[0].SG_ENTIDADE })));
  };

  useEffect(() => {
    if (Object.keys(params).length !== 0) {
      if ('estacao' in params && 'data' in params) {
        getEstacao();
      }
    }
  }, []);

  useEffect(() => {
    if (estacao) {
      if (estacao.codigo.charAt(0) === 'A'
        || estacao.codigo.charAt(0) === 'S'
        || estacao.codigo.charAt(0) === 'V'
        || estacao.codigo.charAt(0) === 'F'
        || estacao.codigo.charAt(0) === 'C'
        || estacao.codigo.charAt(0) === 'I'
        || estacao.codigo.charAt(0) === 'B') {
        setTipoEstacao('T');
      } else {
        setTipoEstacao('M');
      }
    }
  }, [estacao]);

  if (estacao && tipoEstacao) {
    if (tipoEstacao === 'T') {
      return <Automaticas estacao={estacao} />;
    }

    if (tipoEstacao === 'M') {
      return <Convencionais estacao={estacao} />;
    }
  }

  return null;
}

export default Index;
