/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import { ProdutoProvider } from './Produto';
import { SubmitProvider } from './Submit';
import { SubmitPostProvider } from './SubmitPost';
import { VisibleSidebarProvider } from './VisibleSidebar';
import CombineComponents from './CombineProviders';

const providers = [
  ProdutoProvider,
  SubmitProvider,
  SubmitPostProvider,
  VisibleSidebarProvider,
];

const AppContextProvider = CombineComponents(...providers);

export default AppContextProvider;
